import { makeStyles } from '@material-ui/core';

const useTrackingTimeLineStyles = makeStyles(
  ( theme ) => ({
    trackingTimeline : {
      '&.MuiTimeline-root' : {
        paddingLeft  : theme.padding( 0 ),
        paddingRight : theme.padding( 0 )
      },
      '& .MuiTimelineOppositeContent-root' : {
        flex           : 0.2,
        display        : 'flex',
        justifyContent : 'flex-end',
        padding        : `0 ${ theme.padding( 1 ) }`
      },
      '& .MuiTimelineDot-root' : {
        padding     : 0,
        borderWidth : '1px'
      },
      [ theme.breakpoints.up( 'md' ) ] : {
        '& .MuiTimelineOppositeContent-root' : {
          padding : `0 ${ theme.padding( 3 ) }`
        }
      }
    },
    customTimelineDot : {
      '& svg' : {
        width  : '18px',
        height : '18px'
      }
    },
    trackingTimeLineContent : {
      paddingRight : theme.padding( 0 ),
      paddingLeft  : theme.padding( 1 ),

      [ theme.breakpoints.up( 'sm' ) ] : {
        paddingLeft : theme.padding( 2 )
      }
    },
    trackingTimeLineItem : {
    },
    trackingTimeLineButton : {
      width  : '79px',
      height : '23px',

      [ theme.breakpoints.up( 'md' ) ] : {
        width  : '85px',
        height : '25px'
      }
    },
    customTimeLineAlfredTime : {
      marginTop : theme.padding( 1 )
    }
  })
);
export default useTrackingTimeLineStyles;
