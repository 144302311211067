import { createTheme } from '@material-ui/core';

import * as colors from './colors';


export const fontWeightMap = {
  black     : 900,
  extraBold : 800,
  bold      : 700,
  semiBold  : 600,
  medium    : 500,
  regular   : 400,
  light     : 300,
  thin      : 100
};

// A custom Material UI theme for this project
const theme = createTheme({
  palette : {
    primary : {
      dark         : colors.COLOR_PRIMARY_DARK,
      main         : colors.COLOR_PRIMARY_MAIN,
      light        : colors.COLOR_PRIMARY_LIGHT,
      lighter      : colors.COLOR_PRIMARY_LIGHTER,
      white        : colors.COLOR_PRIMARY_WHITE,
      contrastText : colors.COLOR_PRIMARY_WHITE
    },
    secondary : {
      dark    : colors.COLOR_SECONDARY_DARK,
      main    : colors.COLOR_SECONDARY_MAIN,
      light   : colors.COLOR_SECONDARY_LIGHT,
      lighter : colors.COLOR_SECONDARY_LIGHTER
    },
    gradient : {
      dark      : colors.GRADIENT_DARK,
      main      : colors.GRADIENT_MAIN,
      light     : colors.GRADIENT_LIGHT,
      otherDark : colors.GRADIENT_OTHER_DARK
    },
    grey : {
      10  : colors.COLOR_GREY_10,
      50  : colors.COLOR_GREY_50,
      100 : colors.COLOR_GREY_100,
      200 : colors.COLOR_GREY_200,
      300 : colors.COLOR_GREY_300,
      400 : colors.COLOR_GREY_400,
      500 : colors.COLOR_GREY_500,
      600 : colors.COLOR_GREY_600,
      700 : colors.COLOR_GREY_700
    },
    success : {
      dark  : colors.COLOR_SUCCESS_DARK,
      main  : colors.COLOR_SUCCESS_MAIN,
      light : colors.COLOR_SUCCESS_LIGHT
    },
    warning : {
      dark  : colors.COLOR_WARNING_DARK,
      main  : colors.COLOR_WARNING_MAIN,
      light : colors.COLOR_WARNING_LIGHT
    },
    error : {
      dark  : colors.COLOR_ERROR_DARK,
      main  : colors.COLOR_ERROR_MAIN,
      light : colors.COLOR_ERROR_LIGHT
    },
    info : {
      dark  : colors.COLOR_INFO_DARK,
      main  : colors.COLOR_INFO_MAIN,
      light : colors.COLOR_INFO_LIGHT
    },
    background : {
      main       : 'linear-gradient(205.43deg, #0C1C44 -0.24%, #171E3A 101.37%);',
      membership : {
        main : `linear-gradient(90deg, ${ colors.COLOR_PRIMARY_LIGHT } 0%, ${ colors.COLOR_SECONDARY_LIGHT } 100%)`
      }
    },
    link : {
      main : colors.COLOR_PRIMARY_MAIN
    },
    body : {
      main : colors.COLOR_GREY_400
    }
  },
  shadow : {
    card : '0px 10px 20px rgba(46, 91, 255, 0.144005)'
  },
  margin    : ( factor ) => `${ 5 * factor }px`,
  padding   : ( factor ) => `${ 5 * factor }px`,
  overrides : {
    MuiCssBaseline : {
      '@global' : {
        '#root, #modal-root' : {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar' : {
            backgroundColor : 'transparent'
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb' : {
            borderRadius    : 8,
            backgroundColor : colors.COLOR_SECONDARY_DARK,
            minHeight       : 20,
            border          : `3px solid ${ colors.COLOR_GREY_600 }`
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover' : {
            backgroundColor : '#000'
          }
        },
        body : {
          '& .grecaptcha-badge' : { visibility: 'hidden' },
          backgroundColor       : colors.COLOR_PRIMARY_DARK
        }
      }
    },
    MuiStepConnector : {
      line : {
        borderColor : colors.COLOR_GREY_200
      }
    },
    MuiTypography : {
      root : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiFormLabel : {
      root : {
        '&.Mui-disabled' : {
          color : colors.COLOR_GREY_500
        }
      }
    },
    MuiSvgIcon : {
      root : {
        fill : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiSelect : {
      root : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiTextField : {

      root : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiOutlinedInput : {
      root : {
        '&$focused $notchedOutline' : {
          borderColor : colors.COLOR_PRIMARY_WHITE
        },
        '&:hover fieldset' : {
          borderColor : `${ colors.COLOR_PRIMARY_WHITE } !important`
        }
      },
      notchedOutline : {
        borderColor : colors.COLOR_GREY_200
      },
      '&$focused' : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiInput : {
      underline : {
        '&:hover:not($disabled):after' : {
          borderBottomColor : colors.COLOR_PRIMARY_WHITE
        },
        '&:hover:not($disabled):before' : {
          borderBottomColor : colors.COLOR_PRIMARY_WHITE
        },
        '&:before' : {
          borderBottomColor : colors.COLOR_PRIMARY_WHITE
        },
        '&:after' : {
          borderBottomColor : colors.COLOR_PRIMARY_WHITE
        }
      },
      label : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiInputBase : {
      inputMultiline : {
        color : colors.COLOR_PRIMARY_WHITE
      },
      input : {
        '&:-webkit-autofill' : {
          transitionDelay : '9999s',
          backgroundColor : `${ colors.COLOR_PRIMARY_DARK } !important`
        },
        '&$disabled' : {
          opacity : 0.7
        },
        color : colors.COLOR_PRIMARY_WHITE
      },
      label : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiInputLabel : {
      root : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiFormHelperText : {
      root : {
        color : colors.COLOR_PRIMARY_WHITE
      }
    },
    MuiLinearProgress : {
      root : {
        height : '3px'
      }
    },
    MuiTab : {
      root : {
        '&$selected span' : {
          background                : colors.GRADIENT_MAIN,
          '-webkit-background-clip' : 'text',
          '-webkit-text-fill-color' : 'transparent',
          textDecoration            : `underline ${ colors.GRADIENT_MAIN }`,
          display                   : 'block'
        },
        '&:hover span' : {
          background                : colors.GRADIENT_MAIN,
          '-webkit-background-clip' : 'text',
          '-webkit-text-fill-color' : 'transparent',
          textDecoration            : `underline ${ colors.COLOR_PRIMARY_MAIN }`,
          display                   : 'block'
        },
        color : `${ colors.COLOR_GREY_400 } !important`
      }
    },
    MuiAccordion : {
      root : {
        '&:before' : {
          display : 'none'
        }
      }
    }
  }
});

theme.palette.components = {
  button : {
    root : {
      background : theme.palette.gradient.main,
      color      : theme.palette.primary.dark,
      progress   : theme.palette.primary.dark
    },
    primary : {
      background : theme.palette.grey[ 600 ],
      color      : theme.palette.grey[ 50 ],
      progress   : theme.palette.gradient.main
    },
    secondary : {
      background : theme.palette.primary.light,
      color      : theme.palette.grey[ 50 ],
      progress   : theme.palette.primary.dark
    },
    outlined : {
      background : 'inherit',
      border     : theme.palette.primary.light,
      color      : theme.palette.primary.light,
      progress   : theme.palette.primary.light
    }
  },
  customDatePicker : {
    background : theme.palette.grey[ 600 ],
    color      : theme.palette.primary.contrastText,
    selected   : {
      background : theme.palette.primary.main,
      color      : theme.palette.primary.dark
    },
    keyboard : {
      background : 'transparent'
    },
    disabled : {
      color : theme.palette.grey[ 500 ]
    },
    today : {
      background : theme.palette.grey[ 500 ]
    }
  },
  customDateGroup : {
    customDateGroupSelectedBtn : {
      '&.MuiButton-outlined' : {
        color           : theme.palette.primary.white,
        backgroundColor : theme.palette.primary.light,

        '&:hover' : {
          backgroundColor : theme.palette.gradient.blue
        }
      }
    }

  },
  laborPrice : {
    laborTurboPrice : theme.palette.secondary.main,
    laborDiscount   : theme.palette.grey[ 400 ]
  },
  serviceCard : {
    serviceCardSelected : {
      background : {
        selected    : theme.palette.grey[ 600 ],
        notSelected : theme.palette.grey[ 600 ]
      },
      border : {
        selected    : `1px solid ${ theme.palette.primary.main }`,
        notSelected : 'none'
      }
    },
    serviceCardTitle : {
      color : {
        selected    : theme.palette.primary.white,
        notSelected : theme.palette.primary.white
      }
    },
    serviceCardCheckIcon : {
      background : `-webkit-${ theme.palette.gradient.main }`,
      color      : theme.palette.primary.white
    }
  },
  summary : {
    summaryFooter : {
      backgroundColor : theme.palette.secondary.dark
    },
    summaryTitleText : {
      background                : theme.palette.gradient.main,
      '-webkit-background-clip' : 'text',
      '-webkit-text-fill-color' : 'transparent'
    }
  },
  customItem : {
    background       : theme.palette.grey[ 600 ],
    footerBackground : theme.palette.grey[ 500 ],
    footerColor      : colors.COLOR_PRIMARY_WHITE
  },
  customChip : {
    background : colors.COLOR_PRIMARY_DARK,
    color      : theme.palette.grey[ 50 ]
  },
  successfulRequestService : {
    succesfulRequestServiceText1 : {
      background                : theme.palette.gradient.main,
      '-webkit-background-clip' : 'text',
      '-webkit-text-fill-color' : 'transparent'
    }
  },
  checkbox : {
    label : theme.palette.grey[ 50 ],
    icon  : theme.palette.grey[ 50 ]
  },
  table : {
    item                    : theme.palette.primary.contrastText,
    pagination              : theme.palette.primary.contrastText,
    disabledArrowPagination : theme.palette.grey[ 500 ],
    title                   : {
      background                : theme.palette.gradient.main,
      '-webkit-background-clip' : 'text',
      '-webkit-text-fill-color' : 'transparent',
      marginBottom              : theme.margin( 3 )
    }
  },
  customDialog : {
    customDialogTitle : {
      background : theme.palette.primary.dark
    },
    customDialogContent : {
      background : theme.palette.primary.dark
    },
    customDialogFooter : {
      background : theme.palette.primary.dark
    }
  },
  cart : {
    icon       : theme.palette.grey[ 50 ],
    text       : theme.palette.primary.dark,
    background : theme.palette.gradient.main
  },
  carousel : {
    swiperButton : theme.palette.primary.main
  },
  splash : {
    background : theme.palette.gradient.dark
  },
  customAlert : {
    boldColor : theme.palette.primary.main
  },
  error : {
    backgroundColor : theme.palette.gradient.dark,

    text : {
      background                : theme.palette.gradient.main,
      '-webkit-background-clip' : 'text',
      '-webkit-text-fill-color' : 'transparent'
    }
  }
};

// Override font sizes and make them responsive according to Alfred Design System
theme.typography.h1 = {
  fontSize   : '40px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '40px',
  fontWeight : fontWeightMap.bold,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '50px',
    lineHeight : '50px'
  }
};

theme.typography.h2 = {
  fontSize   : '35px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '35px',
  fontWeight : fontWeightMap.regular,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '40px',
    lineHeight : '40px'
  }
};

theme.typography.h3 = {
  fontSize   : '30px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '30px',
  fontWeight : fontWeightMap.bold,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '35px',
    lineHeight : '35px'
  }
};

theme.typography.h4 = {
  fontSize   : '25px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '25px',
  fontWeight : fontWeightMap.bold,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '30px',
    lineHeight : '30px'
  }
};

theme.typography.h5 = {
  fontSize   : '20px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '20px',
  fontWeight : fontWeightMap.bold,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '25px',
    lineHeight : '25px'
  }
};

theme.typography.h6 = {
  fontSize   : '15px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '15px',
  fontWeight : fontWeightMap.regular,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '25px',
    lineHeight : '25px'
  }
};

theme.typography.subtitle1 = {
  fontSize   : '14px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '16px',
  fontWeight : fontWeightMap.bold,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '18px',
    lineHeight : '20px'
  }
};

theme.typography.subtitle2 = {
  fontSize   : '14px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '16px',
  fontWeight : fontWeightMap.medium,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '14px',
    lineHeight : '14px'
  }
};

theme.typography.body1 = {
  fontSize   : '12px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '13px',
  fontWeight : fontWeightMap.regular,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '14px',
    lineHeight : '16px'
  }
};

theme.typography.body2 = {
  fontSize   : '12px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '13px',
  fontWeight : fontWeightMap.medium,

  [ theme.breakpoints.up( 'sm' ) ] : {
    lineHeight : '12px'
  }
};

theme.typography.button = {
  fontSize   : '10px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '10px',
  fontWeight : fontWeightMap.medium,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '12px',
    lineHeight : '12px'
  }
};

theme.typography.caption = {
  fontSize   : '10px',
  fontFamily : 'Gotham, sans-serif',
  fontWeight : fontWeightMap.regular,
  lineHeight : '10px'
};

theme.typography.overline = {
  fontSize   : '8px',
  fontFamily : 'Gotham, sans-serif',
  lineHeight : '8px',
  fontWeight : fontWeightMap.bold,

  [ theme.breakpoints.up( 'sm' ) ] : {
    fontSize   : '10px',
    lineHeight : '12px'
  }
};


export default theme;
