import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import navJS from '../../utils/systemDetector';
import gtm from '../../helpers/gtm';

const GetApp = () => {
  const [ searchParams ] = useSearchParams();
  const [ utmCampaign, setUtmCampaign ] = useState( '' );
  const [ utmSource, setUtmSource ] = useState( '' );
  const [ utmMedium, setUtmMedium ] = useState( '' );
  const [ utmContent, setUtmContent ] = useState( '' );
  const [ loadedQueries, setLoadedQueries ] = useState( false );

  useEffect( () => {
    if( searchParams.has( 'utm_campaign' ) ) {
      setUtmCampaign( searchParams.get( 'utm_campaign' ) );
    }
    if( searchParams.has( 'utm_source' ) ) {
      setUtmSource( searchParams.get( 'utm_source' ) );
    }
    if( searchParams.has( 'utm_medium' ) ) {
      setUtmMedium( searchParams.get( 'utm_medium' ) );
    }
    if( searchParams.has( 'utm_content' ) ) {
      setUtmContent( searchParams.get( 'utm_content' ) );
    }
    setLoadedQueries( true );
  }, [ searchParams ] );

  if( !loadedQueries ) return null;

  if( navJS.isIOS() ) {
    gtm.onOpenLink({
      via : 'iOS', utmCampaign, utmSource, utmMedium, utmContent
    });
    window.location = `https://apps.apple.com/app/apple-store/id1659925747?pt=120972850&ct=${ utmCampaign }&mt=8`;
  }
  else if( navJS.isAndroid() ) {
    gtm.onOpenLink({
      via : 'Android', utmCampaign, utmSource, utmMedium, utmContent
    });
    window.location = `https://play.google.com/store/apps/details?id=co.alfred&utm_source=${ utmSource }&utm_medium=${ utmMedium }&utm_campaign=${ utmCampaign }&utm_content=${ utmContent }`;
  }
  else {
    gtm.onOpenLink({
      via : 'Web', utmCampaign, utmSource, utmMedium, utmContent
    });
  }

  return (
    <div>
      <Navigate to={ `/?utm_source=${ utmSource }&utm_medium=${ utmMedium }&utm_campaign=${ utmCampaign }&utm_content=${ utmContent }` } />
      <h1>GetApp</h1>
      <a href={ `https://apps.apple.com/app/apple-store/id1659925747?pt=120972850&ct=${ utmCampaign }&mt=8` }>iOS</a>
      <a href={ `https://play.google.com/store/apps/details?id=co.alfred&utm_source=${ utmSource }&utm_medium=${ utmMedium }&utm_campaign=${ utmCampaign }&utm_content=${ utmContent }` }>Android</a>
    </div>
  );
};

export default GetApp;
