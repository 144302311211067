import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import useTrackingImageStyles from './trackingImageStyles';
import { ReactComponent as DriverAvatar } from '../../../assets/icons/alfredDriver.svg';

const ALFREDS_IMAGES = process.env.REACT_APP_AWS_S3_ALFREDS;

const TrackingImage = ({ serviceLabor }) => {
  const classes = useTrackingImageStyles();
  const [ image, setImage ] = useState( null );

  const checkImage = useCallback( ( imageSrc, good, bad ) => {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }, [ ] );

  useEffect( () => {
    if( !serviceLabor ) return;
    if( !serviceLabor.alfred ) return;
    const url = `${ ALFREDS_IMAGES + serviceLabor.alfred?.uuid }.jpg`;
    const good = () => {
      setImage( url );
    };
    const bad = () => {
      setImage( null );
    };
    checkImage( url, good, bad );
  }, [ serviceLabor, checkImage ] );


  return (
    <>
      {
        image ? (
          <Grid container justifyContent="flex-end" className={ classes.trackingImageContainer }>
            <img src={ image } alt="avatar-timeline" className={ classes.trackingImage } />
          </Grid>
        )
        : serviceLabor.alfred && (
          <Grid container justifyContent="flex-end" className={ classes.trackingSVGContainer }>
            <DriverAvatar />
          </Grid>
        )
      }
    </>
  );
};

export default TrackingImage;
