import { publicGet } from './BaseService';


async function getTracking( serviceUuid ) {
  return publicGet( `services/${ serviceUuid } ` );
}

export default {
  getTracking
};
