import PropTypes from 'prop-types';

export const INTERFACE_ADD_TO_CART = {
  items : PropTypes.arrayOf( PropTypes.shape({
    item_name      : PropTypes.string,
    item_id        : PropTypes.string,
    price          : PropTypes.string,
    item_brand     : PropTypes.string,
    item_category  : PropTypes.string,
    item_variant   : PropTypes.string,
    item_list_name : PropTypes.string,
    item_list_id   : PropTypes.string,
    quantity       : PropTypes.string
  }) )
};

export const INTERFACE_CART = {
  items : PropTypes.arrayOf( PropTypes.shape({
    item_name     : PropTypes.string,
    item_id       : PropTypes.string,
    price         : PropTypes.string,
    item_brand    : PropTypes.string,
    item_category : PropTypes.string,
    item_variant  : PropTypes.string,
    quantity      : PropTypes.string
  }) )
};

export const INTERFACE_PROMOTION = {
  items : PropTypes.arrayOf( PropTypes.shape({
    promotion_id   : PropTypes.string,
    promotion_name : PropTypes.string,
    creative_name  : PropTypes.string,
    creative_slot  : PropTypes.string,
    location_id    : PropTypes.string
  }) )
};

export const INTERFACE_ITEMS = {
  items : PropTypes.arrayOf( PropTypes.shape({
    item_name      : PropTypes.string,
    item_id        : PropTypes.string,
    price          : PropTypes.string,
    item_brand     : PropTypes.string,
    item_category  : PropTypes.string,
    item_variant   : PropTypes.string,
    item_list_name : PropTypes.string,
    item_list_id   : PropTypes.string,
    index          : PropTypes.number,
    quantity       : PropTypes.string
  }) )
};

export const INTERFACE_PURCHASE = {
  data : PropTypes.objectOf( PropTypes.shape(
    {
      value          : PropTypes.string,
      tax            : PropTypes.string,
      shipping       : PropTypes.string,
      affiliation    : PropTypes.string,
      transaction_id : PropTypes.string,
      coupon         : PropTypes.string,
      items          : INTERFACE_CART
    }
  ) )
};
