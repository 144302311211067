const awsconfig = {
  Auth : {
    region              : process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId          : process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId : process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID,
    mandatorySignIn     : false,

    authenticationFlowType : 'USER_SRP_AUTH',

    oauth : {
      scope : [
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin' ],
      responseType : 'code',
      domain       : process.env.REACT_APP_AWS_COGNITO_DOMAIN,

      redirectSignIn  : process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut : process.env.REACT_APP_REDIRECT_SIGN_OUT
    }
  }
};
export default awsconfig;
