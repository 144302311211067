export function getPaymentProviderTimeoutError( detail ) {
  const error = new Error();
  error.detail = detail;
  error.message = 'Error conectando a proveedor de pagos';
  error.name = 'PaymentProviderTimeoutError';
  if( Error.captureStackTrace ) {
    Error.captureStackTrace( error );
  }
  throw error;
}

export async function getGeneralHandleError( response ) {
  const error = new Error();

  error.detail = {
    statusText : response.statusText,
    status     : response.status,
    url        : response.url
  };
  error.status = response.status;

  if( response.body ) {
    const body = await response.json();
    error.body = body;
  }

  switch ( response.status ) {
    case 404:
      error.message = 'El recurso buscado no se encuentra o no está disponible.';
      error.name = 'ResourceNotFoundError';
      break;
    case ( 412 || 422 ):
      error.message = 'Los datos suministrados no son correctos.';
      error.name = 'IncorrectDataError';
      break;
    case ( 403 || 401 ):
      error.message = 'No tiene permisos suficientes para llevar a cabo esta solicitud';
      error.name = 'NoPermissionError';
      break;
    default:
      error.message = 'Ocurrió un error procesando tu solicitud, favor comunícate con el administrador o inténtalo más tarde.';
      error.name = 'GenericError';
      break;
  }
  if( Error.captureStackTrace ) {
    Error.captureStackTrace( error );
  }
  throw error;
}
