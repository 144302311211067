import { makeStyles } from '@material-ui/core';

const opacityImage = 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ';

const useTrackingRightStyles = makeStyles(
  ( theme ) => ({
    trackingRightInfo : {
      maxWidth   : '720px',
      '& iframe' : {
        minHeight : '100vh',
        width     : 'fill-available',

        '@media( max-width : 960px )' : {
          minHeight : '48vh'
        }
      }
    },
    trackingBackground : {

      backgroundSize                   : 'cover',
      backgroundPosition               : 'center',
      height                           : '80vh',
      [ theme.breakpoints.up( 'md' ) ] : {
        height : 'auto'
      }

    },
    trackingCompleted : {
      backgroundImage : ({ completedImage }) => `${ opacityImage }url( ${ completedImage })`
    },
    trackingCanceled : {
      backgroundImage : ({ canceledImage }) => `${ opacityImage }url( ${ canceledImage })`
    },
    trackingRequested : {
      backgroundImage : ({ requestedImage }) => `${ opacityImage }url( ${ requestedImage })`
    },
    trackingToBeConfirmed : {
      backgroundImage : ({ toBeConfirmedImage }) => `${ opacityImage }url( ${ toBeConfirmedImage })`
    },
    trackingToBePaid : {
      backgroundImage : ({ toBePaidImage }) => `${ opacityImage }url( ${ toBePaidImage })`
    },
    trackingRightMessage : {
      padding : theme.padding( 2 ),
      opacity : 0.9
    },
    trackingLink : {
      maxWidth  : '180px',
      marginTop : theme.margin( 2 )
    },
    trackingTitle : {
      textAlign : 'center'
    },
    trackingText : {
      margin : `${ theme.margin( 2 ) } 0`
    }
  })
);

export default useTrackingRightStyles;
