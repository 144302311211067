import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  cities           : undefined,
  areCitiesLoading : false,
  citiesError      : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    // ---- GET CITIES ----- //
    case actionTypes.GET_CITIES_LOADING:
      return {
        ...state,
        areCitiesLoading : true,
        citiesError      : ''
      };
    case actionTypes.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities           : action.payload,
        areCitiesLoading : false,
        citiesError      : ''
      };
    case actionTypes.GET_CITIES_ERROR:
      return {
        ...state,
        areCitiesLoading : false,
        citiesError      : action.payload
      };
    default:
      return state;
  }
};
