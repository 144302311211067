import { constants } from '@alfred-co/alfred-react-components';
import couponService from '../../services/CouponService';
import {
  CREATE_COUPON_LOADING,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_ERROR,
  COUPON_SERVICE_LOADING,
  COUPON_SERVICE_ERROR,
  COUPON_SERVICE_SUCCESS,
  ADD_COUPON_SERVICE_SUCCESS,
  PAYMENT_GET_SERVICES_SUCCESS,
  DELETE_COUPON_SERVICE_LOADING,
  DELETE_COUPON_SERVICE_SUCCESS,
  DELETE_COUPON_SERVICE_ERROR,
  CLEAR_COUPON_SERVICE
} from '../types';
import { getServicePrice } from '../payment/paymentResumeAction';
import gtm from '../../../helpers/gtm';

const { PAYMENT_ORIGIN_CASE } = constants;

export function getTotals( servicePrice ) {
  return {
    total : {
      subtotal          : servicePrice.subtotal,
      total             : servicePrice.total,
      discounts         : servicePrice.discounts,
      laborDiscount     : servicePrice.laborDiscount,
      advance           : servicePrice.advance,
      transportPrice    : servicePrice.transportPrice,
      transportDiscount : servicePrice.transportDiscount
    }
  };
}

export const createCoupon = ( coupon, couponType ) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type : CREATE_COUPON_LOADING
    });
    const { user } = getState().userReducer;

    const fetchedCoupon = await couponService.createCoupon( user, coupon );
    if( fetchedCoupon
      && fetchedCoupon.baseCoupon
      && fetchedCoupon.baseCoupon.type === couponType ) {
      return dispatch({
        type    : CREATE_COUPON_SUCCESS,
        payload : fetchedCoupon
      });
    }

    return dispatch({
      type    : CREATE_COUPON_ERROR,
      payload : 'Invalid type of coupon'
    });
  }
  catch ( error ) {
    return dispatch({
      type    : CREATE_COUPON_ERROR,
      payload : error.message,
      error
    });
  }
};

export const resetCoupon = () => async ( dispatch ) => dispatch({
  type    : CREATE_COUPON_SUCCESS,
  payload : undefined
});

function getCouponErrorMessage( error ) {
  switch ( error.status ) {
    case 400:
      return 'El cupón no es válido, sólo se puede redimir un cupón por servicio';
    case 404:
      return 'El cupón no es válido';
    case 409:
      return 'El cupón ya fue redimido previamente';
    case 412:
      return 'El cupón ya ha expirado';
    case 422:
      return 'No es posible aplicar cupones sobre servicios que ya han sido pagados';
    default:
      return 'Ocurrió un error inesperado, por favor vuelve a intentar';
  }
}

export const addCouponToService = ( service, baseCoupon ) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type : COUPON_SERVICE_LOADING
    });
    const { user } = getState().userReducer;

    const services = await couponService.addCouponToService( user, service, baseCoupon );

    dispatch({
      type    : PAYMENT_GET_SERVICES_SUCCESS,
      payload : {
        ...services,
        paymentItems : services.serviceLabors
      }
    });

    return dispatch({
      type : ADD_COUPON_SERVICE_SUCCESS
    });
  }
  catch ( error ) {
    return dispatch({
      type    : COUPON_SERVICE_ERROR,
      payload : getCouponErrorMessage( error ),
      error
    });
  }
};

export const validateCoupon = ( coupon, couponType ) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type : COUPON_SERVICE_LOADING
    });
    const { user } = getState().userReducer;
    const { paymentItems, origin, originCase } = getState().paymentReducer;

    const fetchedCoupon = await couponService.validateCoupon(
      user,
      coupon,
      couponType,
      origin.value
    );

    if( fetchedCoupon && fetchedCoupon.type === couponType ) {
      const serviceLabor = paymentItems.find(
        ( item ) => item?.labor?.slug?.toUpperCase() === fetchedCoupon?.data?.labor?.toUpperCase()
      );
      if( serviceLabor ) {
        if( originCase === PAYMENT_ORIGIN_CASE.REQUEST ) {
          dispatch({
            type    : COUPON_SERVICE_SUCCESS,
            payload : fetchedCoupon
          });

          gtm.addCoupon({ eventParams: { success: true } });

          const paymentLabors = paymentItems.map( ( item ) => {
            if( item.labor.slug === serviceLabor.labor.slug ) {
              const labor = item;
              labor.coupon = { baseCoupon: fetchedCoupon };
              return labor;
            }
            return item;
          });

          const servicePriceWithCoupon = paymentLabors.map( ( item ) => {
            if( item.coupon ) {
              return {
                ...item,
                baseCoupon : { id: item.coupon.baseCoupon.id }
              };
            }
            return item;
          });

          await dispatch( getServicePrice( servicePriceWithCoupon ) );

          const { servicePrice } = getState().paymentReducer;

          const services = getTotals( servicePrice );

          return dispatch({
            type    : PAYMENT_GET_SERVICES_SUCCESS,
            payload : {
              ...services,
              paymentItems : paymentLabors
            }
          });
        }

        if( origin.value ) {
          const baseCoupon = {
            baseCoupon   : fetchedCoupon.id,
            serviceLabor : serviceLabor.id
          };
          await dispatch( addCouponToService( origin.value, baseCoupon ) );
          gtm.addCoupon({ eventParams: { success: true } });
          return dispatch({
            type    : COUPON_SERVICE_SUCCESS,
            payload : fetchedCoupon
          });
        }
      }
    }
    gtm.addCoupon({ eventParams: { success: false } });
    return dispatch({
      type    : COUPON_SERVICE_ERROR,
      payload : 'Este cupón no es válido para este tipo de servicio'
    });
  }
  catch ( error ) {
    gtm.addCoupon({ eventParams: { success: false } });
    return dispatch({
      type    : COUPON_SERVICE_ERROR,
      payload : getCouponErrorMessage( error ),
      error
    });
  }
};

export const deleteCouponInService = ( data ) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type : DELETE_COUPON_SERVICE_LOADING
    });

    const { user } = getState().userReducer;
    const { origin, paymentItems } = getState().paymentReducer;

    if( data.coupon ) {
      const services = await couponService.deleteCouponInService( user, origin.value, data );

      dispatch({
        type    : PAYMENT_GET_SERVICES_SUCCESS,
        payload : {
          ...services,
          paymentItems : services.serviceLabors
        }
      });
    }
    else {
      const paymentLabors = paymentItems.map( ( item ) => {
        if( item.id === data.serviceLabor ) {
          const serviceLabor = item;
          serviceLabor.coupon = null;
          return serviceLabor;
        }
        return item;
      });

      await dispatch( getServicePrice( paymentLabors ) );

      const { servicePrice } = getState().paymentReducer;

      const services = getTotals( servicePrice );
      dispatch({
        type    : PAYMENT_GET_SERVICES_SUCCESS,
        payload : {
          ...services,
          paymentItems : paymentLabors
        }
      });
    }

    return dispatch({
      type : DELETE_COUPON_SERVICE_SUCCESS
    });
  }
  catch ( error ) {
    return dispatch({
      type    : DELETE_COUPON_SERVICE_ERROR,
      payload : error.message,
      error
    });
  }
};

export const clearCouponService = ( ) => async ( dispatch ) => (
  dispatch({
    type : CLEAR_COUPON_SERVICE
  })
);
