import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import packageJson from '../../package.json';

export const initBugReporter = () => {
  if( !process.env.REACT_APP_SENTRY_DSN ) {
    return;
  }

  // Global wrapper that reports uncaught errors
  Sentry.init({
    dsn     : process.env.REACT_APP_SENTRY_DSN,
    // enable to print useful information if something goes wrong with sending an event
    debug   : process.env.NODE_ENV === 'development',
    release : packageJson.version,

    attachStacktrace    : true,
    autoSessionTracking : true,

    environment              : process.env.NODE_ENV,
    // used to see complete objects in sentry instead of {Objects}
    normalizeDepth           : 30,
    replaysSessionSampleRate : 0.1,
    replaysOnErrorSampleRate : 1.0,
    integrations             : [
      new Integrations.BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        fetch : true
      }),
      new Sentry.Replay()
    ],
    ignoreErrors : [
      'ibFindAllVideos'
    ]
  });
};

export const reportBug = ( error, level ) => {
  let errorObject = error;
  if( error instanceof Error === false ) {
    errorObject = new Error( error.message || error );
  }
  if( process.env.REACT_APP_DEBUG_MODE === 'true' || !process.env.REACT_APP_SENTRY_DSN ) {
    // eslint-disable-next-line no-console
    console.error( error );
  }
  else {
    Sentry.configureScope(
      ( scope ) => scope
        .setLevel( level )
    );
    Sentry.captureException(
      errorObject,
      ( scope ) => {
        if( error.detail ) {
          scope.setExtra( 'Detail', error.detail );
        }
        return scope;
      }
    );
  }
};

export const bugReporterReduxEnhancer = Sentry.createReduxEnhancer({
  // actionTransormer is used to catch redux errors and to report them to sentry
  actionTransformer : ( action ) => {
    if( action.error ) {
      reportBug( action.error, action.level );
    }
  },
  stateTransformer : ( state ) => {
    const transformedState = {
      ...state,
      userReducer : {
        ...state.userReducer,
        session : state.userReducer?.attributes || undefined,
        user    : state.user?.username || undefined
      }
    };
    return transformedState;
  }
});
