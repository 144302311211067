import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import unauthorizedMiddleware from './middlewares/unauthorizedMiddleware';
import reducers from './reducers';
import { bugReporterReduxEnhancer } from '../utils/bugReportUtils';

let middleware = [ thunk, unauthorizedMiddleware ];
let composeEnhancers = compose;

// Redux logger and bug reporter shouldn't be enabled simultaneously,
// as the payload is too large to be sent.
if( process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEBUG_MODE === 'true' ) {
  middleware = [ ...middleware, logger ];
  composeEnhancers = ( typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose;
}

const store = createStore(
  reducers, // All reducers
  composeEnhancers(
    applyMiddleware( ...middleware ),
    bugReporterReduxEnhancer
  )
);

export default store;
