import { constants } from '@alfred-co/alfred-react-components';
import { format } from 'date-fns';
import { getBlockedSlots } from '../daysOff/daysOffActions';
import { verifyServicePrice } from '../payment/paymentResumeAction';
import { compareAddresses } from '../../../helpers/addressHelper';
import {
  REQUEST_SERVICE_UPDATE_LOADING,
  REQUEST_SERVICE_UPDATE,
  REQUEST_SERVICE_CLEAR,
  REQUEST_SERVICE_STEP,
  REQUEST_SERVICE_SERVICE,
  REQUEST_SERVICE_VALIDATE,
  REQUEST_SERVICE_CATEGORY,
  REQUEST_SERVICE_LABOR,
  REQUEST_SERVICE_SHOP
} from '../types';

const { LOCALSTORAGE, TRANSPORT_LABORS_SLUG } = constants;

function saveServiceRequestDataInLocalStorage( data ) {
  localStorage.setItem( LOCALSTORAGE.REQUEST_SERVICE, JSON.stringify( data ) );
}

export const clearRequestData = () => async ( dispatch ) => {
  localStorage.removeItem( LOCALSTORAGE.REQUEST_SERVICE );
  dispatch({
    type : REQUEST_SERVICE_CLEAR
  });
};

export const loadRequestData = () => async ( dispatch, getState ) => {
  const { labors } = getState().laborReducer;
  const { addresses } = getState().userReducer;

  dispatch({
    type : REQUEST_SERVICE_UPDATE_LOADING
  });
  const initialServiceToRequest = {};
  const initialActiveStepNumber = 0;
  const initialStepValidations = [ false, false, false ];
  const initialSelectedCategory = '';

  const requestStorage = JSON.parse(
    localStorage.getItem( LOCALSTORAGE.REQUEST_SERVICE )
  ) || false;

  if( requestStorage ) {
    requestStorage.serviceToRequest = requestStorage.serviceToRequest || initialServiceToRequest;
    requestStorage.activeStepNumber = requestStorage.activeStepNumber || initialActiveStepNumber;
    requestStorage.stepValidations = requestStorage.stepValidations || initialStepValidations;
    requestStorage.selectedCategory = requestStorage.selectedCategory || initialSelectedCategory;

    if( requestStorage.serviceToRequest?.startAddress ) {
      const startAddress = addresses.find(
        ( address ) => address.uuid === requestStorage.serviceToRequest?.startAddress.uuid
      );

      if( startAddress ) {
        const isSameStartAddress = compareAddresses(
          startAddress, requestStorage.serviceToRequest?.startAddress
        );

        if( !isSameStartAddress ) {
          if( requestStorage.selectedLabor !== '' || requestStorage.serviceToRequest?.serviceLabors?.length ) {
            return dispatch( clearRequestData() );
          }
          requestStorage.serviceToRequest.startAddress = startAddress;
          requestStorage.serviceToRequest.endAddress = startAddress;
          saveServiceRequestDataInLocalStorage( requestStorage );
        }
      }
      else {
        return dispatch( clearRequestData() );
      }
    }


    if( requestStorage.serviceToRequest?.scheduleDate ) {
      let localStorageDate = new Date(
        requestStorage.serviceToRequest.scheduleDate
      );
      const dateNow = new Date();
      dateNow.setHours( dateNow.getHours() + 1 );
      if( localStorageDate < dateNow ) {
        localStorageDate = new Date( new Date().setHours( 0, 0, 0, 0 ) );
      }
      else {
        // force to unset hours
        // because we need to ask for blocked slots refresh and set step 3 incomplete
        localStorageDate.setHours( 0, 0, 0, 0 );
      }
      requestStorage.stepValidations[ 2 ] = false;

      // get Blocked Slots again
      if( requestStorage.serviceToRequest?.startAddress ) {
        const department = requestStorage.serviceToRequest
          .startAddress?.city.data.department_name;
        dispatch( getBlockedSlots( department, format( localStorageDate, 'yyyy-MM-dd' ) ) );
      }
      requestStorage.serviceToRequest.scheduleDate = new Date(
        localStorageDate
      );
    }

    // Validate if it's two days after
    if( requestStorage.date ) {
      const oldDate = new Date( requestStorage.date );
      const dateNow = new Date();
      if( oldDate < dateNow.setDate( dateNow.getDate() - 2 ) ) {
        requestStorage.activeStepNumber = 0;
        requestStorage.serviceToRequest = {};
      }
    }
    // validate if all labors exists
    if( labors
      && requestStorage.serviceToRequest?.serviceLabors
      && requestStorage.serviceToRequest?.serviceLabors?.length ) {
      const existLabors = requestStorage.serviceToRequest.serviceLabors.filter(
        ( serviceLabor ) => {
          const laborExists = [ ...labors.laborsWithPrice, ...labors.laborsWithOutPrice ]
            .some( ( labor ) => labor.id === serviceLabor.labor.id );
          return laborExists ? serviceLabor : null;
        }
      );
      if( existLabors.length !== requestStorage.serviceToRequest?.serviceLabors?.length ) {
        requestStorage.serviceToRequest.serviceLabors = existLabors;
        requestStorage.activeStepNumber = 1;
      }
      dispatch( verifyServicePrice( requestStorage.serviceToRequest.serviceLabors ) );
    }

    // validate if the first step is completed
    if( !requestStorage.serviceToRequest?.vehicle ) {
      requestStorage.activeStepNumber = 0;
    }
    return dispatch({
      type    : REQUEST_SERVICE_UPDATE,
      payload : requestStorage
    });
  }
  return dispatch({
    type    : REQUEST_SERVICE_UPDATE,
    payload : {
      activeStepNumber : initialActiveStepNumber,
      serviceToRequest : initialServiceToRequest,
      stepValidations  : initialStepValidations,
      selectedCategory : initialSelectedCategory
    }
  });
};

export const updateRequestData = ({
  serviceToRequest,
  stepValidations,
  activeStepNumber,
  selectedCategory,
  selectedLabor,
  selectedShop
}) => async ( dispatch ) => {
  const requestStorage = JSON.parse(
    localStorage.getItem( LOCALSTORAGE.REQUEST_SERVICE )
  ) || {};
  requestStorage.date = new Date();

  if( serviceToRequest ) {
    const withoutTransport = serviceToRequest?.serviceLabors?.filter(
      ( labor ) => labor.labor.id !== TRANSPORT_LABORS_SLUG[ 0 ]
    );
    const newServiceToRequest = { ...serviceToRequest, serviceLabors: withoutTransport };
    dispatch({
      type    : REQUEST_SERVICE_SERVICE,
      payload : withoutTransport?.length ? serviceToRequest : newServiceToRequest
    });
    requestStorage.serviceToRequest = newServiceToRequest;
  }
  if( stepValidations ) {
    dispatch({
      type    : REQUEST_SERVICE_VALIDATE,
      payload : stepValidations
    });
    requestStorage.stepValidations = stepValidations;
  }
  if( activeStepNumber !== undefined && !Number.isNaN( activeStepNumber ) ) {
    dispatch({
      type    : REQUEST_SERVICE_STEP,
      payload : activeStepNumber
    });
    requestStorage.activeStepNumber = activeStepNumber;
  }
  if( selectedLabor !== undefined ) {
    dispatch({
      type    : REQUEST_SERVICE_LABOR,
      payload : selectedLabor
    });
    requestStorage.selectedLabor = selectedLabor;
  }
  if( selectedCategory !== undefined ) {
    dispatch({
      type    : REQUEST_SERVICE_CATEGORY,
      payload : selectedCategory
    });
  }
  if( selectedShop !== undefined ) {
    dispatch({
      type    : REQUEST_SERVICE_SHOP,
      payload : selectedShop
    });
    requestStorage.selectedShop = selectedShop;
  }
  requestStorage.selectedCategory = selectedCategory ?? requestStorage.selectedCategory;
  requestStorage.selectedLabor = selectedLabor ?? requestStorage.selectedLabor;
  requestStorage.selectedShop = selectedShop ?? requestStorage.selectedShop;
  saveServiceRequestDataInLocalStorage( requestStorage );
};

export const clearAddressInsideRequestData = ( addressUuid ) => async ( dispatch ) => {
  const requestStorage = JSON.parse(
    localStorage.getItem( LOCALSTORAGE.REQUEST_SERVICE )
  ) || false;

  if( requestStorage ) {
    if( requestStorage.serviceToRequest?.endAddress?.uuid === addressUuid ) {
      delete requestStorage.serviceToRequest.endAddress;
    }
    if( requestStorage.serviceToRequest?.startAddress?.uuid === addressUuid ) {
      delete requestStorage.serviceToRequest.startAddress;
    }
    // this is necessary to prevent to change the date format
    if( requestStorage.serviceToRequest?.scheduleDate ) {
      requestStorage.serviceToRequest.scheduleDate = new Date(
        requestStorage.serviceToRequest.scheduleDate
      );
    }
    saveServiceRequestDataInLocalStorage( requestStorage );
    dispatch({
      type    : REQUEST_SERVICE_UPDATE,
      payload : requestStorage
    });
  }
};

export const getSelectedAddress = ( ) => ( dispatch ) => {
  const requestStorage = JSON.parse(
    localStorage.getItem( LOCALSTORAGE.REQUEST_SERVICE )
  ) || false;

  if( requestStorage ) {
    const initialServiceToRequest = {};
    requestStorage.serviceToRequest = requestStorage.serviceToRequest || initialServiceToRequest;

    dispatch({
      type    : REQUEST_SERVICE_UPDATE,
      payload : requestStorage
    });
  }
};
