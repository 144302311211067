import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  activeStepNumber          : 0,
  serviceToRequest          : undefined,
  stepValidations           : [ false, false, false ],
  selectedCategory          : '',
  serviceToRequestIsLoading : false,
  selectedLabor             : '',
  selectedShop              : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.REQUEST_SERVICE_UPDATE_LOADING:
      return {
        ...state,
        serviceToRequestIsLoading : true
      };
    case actionTypes.REQUEST_SERVICE_UPDATE:
      return {
        ...state,
        ...action.payload,
        serviceToRequestIsLoading : false
      };
    case actionTypes.REQUEST_SERVICE_CLEAR:
      return {
        ...INITIAL_STATE
      };
    case actionTypes.REQUEST_SERVICE_STEP:
      return {
        ...state,
        activeStepNumber : action.payload
      };
    case actionTypes.REQUEST_SERVICE_SERVICE:
      return {
        ...state,
        serviceToRequest : action.payload
      };
    case actionTypes.REQUEST_SERVICE_VALIDATE:
      return {
        ...state,
        stepValidations : action.payload
      };
    case actionTypes.REQUEST_SERVICE_CATEGORY:
      return {
        ...state,
        selectedCategory : action.payload
      };
    case actionTypes.REQUEST_SERVICE_LABOR:
      return {
        ...state,
        selectedLabor : action.payload
      };
    case actionTypes.REQUEST_SERVICE_SHOP:
      return {
        ...state,
        selectedShop : action.payload
      };


    default:
      return state;
  }
};
