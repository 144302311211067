import { TRACKING_SERVICE_ERROR, TRACKING_SERVICE_LOADING, TRACKING_SERVICE_SUCCESS } from '../types';
import trackingService from '../../services/trackingService';


export const getTracking = ( trackingNumber ) => async ( dispatch ) => {
  try {
    dispatch({
      type : TRACKING_SERVICE_LOADING
    });

    const trackingData = await trackingService.getTracking( trackingNumber );

    if( trackingData?.serviceLabors.length > 1 ) {
      trackingData.serviceLabors = trackingData.serviceLabors
        .sort( ( a, b ) => new Date( a.scheduleDate ) - new Date( b.scheduleDate ) );
    }

    let lastEvent = null;
    let activeLabor = null;
    if( trackingData?.serviceLabors.length > 0 ) {
      const lastLabor = trackingData?.serviceLabors[ trackingData?.serviceLabors?.length - 1 ];
      if( lastLabor?.events?.length > 0 ) {
        lastEvent = lastLabor?.events[ lastLabor?.events?.length - 1 ];
      }
      activeLabor = trackingData?.serviceLabors.find(
        ( labor ) => ( labor?.startDate && !labor?.endDate )
      );
    }

    return dispatch({
      type    : TRACKING_SERVICE_SUCCESS,
      payload : { trackingData, lastEvent, activeLabor }
    });
  }
  catch ( error ) {
    return dispatch({
      type    : TRACKING_SERVICE_ERROR,
      payload : error.message,
      error
    });
  }
};

export const setTrackingMap = ( map, showMap ) => ({
  type    : 'TRACKING_SET_MAP',
  payload : { map, showMap }
});
