import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { constants, CustomButton, dateUtils } from '@alfred-co/alfred-react-components';

import { servicePath } from '../../../utils/paths';
import useTrackingRightStyles from './trackingRightStyles';

const { toLongDateWithMinutes } = dateUtils;
const { SERVICE_STATUS } = constants;

const canceledImage = `${ process.env.REACT_APP_AWS_S3_ASSETS_BUCKET }/canceled.webp`;
const completedImage = `${ process.env.REACT_APP_AWS_S3_ASSETS_BUCKET }/completed.webp`;
const requestedImage = `${ process.env.REACT_APP_AWS_S3_ASSETS_BUCKET }/requested.webp`;
const toBeConfirmedImage = `${ process.env.REACT_APP_AWS_S3_ASSETS_BUCKET }/toBeConfirmed.webp`;
const toBePaidImage = `${ process.env.REACT_APP_AWS_S3_ASSETS_BUCKET }/toBePaid.webp`;

export const MAP_LIFETIME = 28;

const TrackingRight = () => {
  const classes = useTrackingRightStyles({
    canceledImage,
    completedImage,
    requestedImage,
    toBeConfirmedImage,
    toBePaidImage
  });
  const [ searchParams ] = useSearchParams();
  const {
    map,
    lastEvent,
    showMap,
    tracking
  } = useSelector( ( state ) => state.trackingReducer );
  const navigate = useNavigate();

  const loadMap = useMemo( () => {
    if( !map ) return false;

    if( lastEvent?.executionDate ) {
      const today = new Date();
      const dateToCompare = new Date( lastEvent.executionDate );
      const diff = today.getTime() - dateToCompare.getTime();
      const diffDays = Math.ceil( diff / ( 1000 * 3600 * 24 ) );
      if( diffDays > MAP_LIFETIME ) return false;
    }


    if( searchParams.get( 'map' ) ) return true;
    if( showMap ) return true;
    if( tracking.state === SERVICE_STATUS.IN_PROGRESS ) return true;

    return false;
  }, [ searchParams, map, lastEvent, showMap, tracking ] );

  const getImageClass = useMemo( () => {
    switch ( tracking.state ) {
      case SERVICE_STATUS.CANCELED:
        return classes.trackingCanceled;
      case SERVICE_STATUS.COMPLETED:
        return classes.trackingCompleted;
      case SERVICE_STATUS.REQUESTED:
        return classes.trackingRequested;
      case SERVICE_STATUS.TO_BE_CONFIRMED:
        return classes.trackingToBeConfirmed;
      case SERVICE_STATUS.TO_BE_PAID:
        return classes.trackingToBePaid;
      default:
        return classes.trackingRequested;
    }
  }, [ tracking, classes ] );

  const getMessages = useMemo( () => {
    switch ( tracking.state ) {
      case SERVICE_STATUS.CANCELED:
        return {
          title    : 'Servicio cancelado',
          subTitle : 'Tu servicio ha sido cancelado'
        };
      case SERVICE_STATUS.COMPLETED:
        return {
          title    : '¡Gracias por confiar en nosotros!',
          subTitle : 'Tu servicio ha sido completado.'
        };
      case SERVICE_STATUS.IN_PROGRESS:
        return {
          title    : 'Servicio actual',
          subTitle : 'Tu servicio se encuentra en progreso.'
        };
      case SERVICE_STATUS.REQUESTED:
        return {
          title    : 'Servicio agendado',
          subTitle : `Tu servicio se ha agendado para el ${ toLongDateWithMinutes( new Date( tracking.scheduleDate ) ) }`
        };
      case SERVICE_STATUS.TO_BE_CONFIRMED:
        return {
          title    : undefined,
          subTitle : undefined
        };
      case SERVICE_STATUS.TO_BE_PAID:
        return {
          title    : undefined,
          subTitle : undefined
        };
      default:
        return {
          title    : '¡Gracias por confiar en nosotros!',
          subTitle : 'Tu servicio ha sido completado.'
        };
    }
  }, [ tracking ] );

  const handleClick = useCallback( () => {
    navigate( `${ servicePath }/${ tracking.uuid }` );
  }, [ navigate, tracking ] );

  return (
    <>
      {
        loadMap && (
          <iframe src={ map } title="map" className={ classes.trackingIframe } />
        )
      }
      {
        !loadMap && (
          <Grid container item direction="column" justifyContent="center" className={ `${ getImageClass } ${ classes.trackingBackground }` }>

            {
              getMessages.title && (
                <Grid container item direction="column" alignItems="center" className={ classes.trackingRightMessage }>
                  <Typography variant="h4" className={ classes.trackingTitle }>{ getMessages.title }</Typography>
                  <Typography variant="body1" className={ classes.trackingText }>{ getMessages.subTitle }</Typography>
                  <CustomButton
                    onClick={ handleClick }
                    className={ classes.trackingLink }
                  >
                    Ir a pagos
                  </CustomButton>
                </Grid>
              )
            }

          </Grid>
        )
      }
    </>
  );
};

export default TrackingRight;
