import { constants } from '@alfred-co/alfred-react-components';
import * as actionTypes from '../actions/types';


const { TRANSPORT_LABORS_SLUG } = constants;

const INITIAL_STATE = {
  paymentItems          : [ ],
  showCoupon            : false,
  paymentUUID           : undefined,
  isUuidLoading         : false,
  origin                : { type: '', value: '', id: '' },
  status                : undefined,
  paymentError          : undefined,
  couponError           : undefined,
  coupon                : undefined,
  activeCoupons         : [],
  isCouponLoading       : false,
  isServicesLoading     : false,
  servicesError         : undefined,
  total                 : 0,
  discounts             : 0,
  laborDiscount         : 0,
  advance               : 0,
  serviceState          : undefined,
  transport             : undefined,
  isTransportLoading    : false,
  transportError        : undefined,
  transportPrice        : 0,
  transportDiscount     : 0,
  subTotal              : 0,
  servicePrice          : 0,
  isServicePriceLoading : false,
  servicePriceError     : '',
  couponsAssigned       : 0
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.PAYMENT_ADD_PAYMENTS_RESUME:
      return {
        ...state,
        originCase   : action.payload.originCase,
        paymentItems : action.payload.items,
        origin       : action.payload.service,
        showCoupon   : true
      };
    case actionTypes.PAYMENT_SHOW_COUPON:
      return {
        ...state,
        showCoupn : action.payload
      };
    case actionTypes.PAYMENT_GENERATE_UUID:
      return {
        ...state,
        paymentUUID : action.payload
      };
    case actionTypes.PAYMENT_UUID_LOADING:
      return {
        ...state,
        isUuidLoading : true,
        paymentError  : ''
      };
    case actionTypes.PAYMENT_UUID_SUCCESS:
      return {
        ...state,
        isUuidLoading : false
      };
    case actionTypes.PAYMENT_UUID_CLEAR:
      return {
        ...state,
        paymentUUID  : undefined,
        paymentItems : [],
        origin       : { type: '', value: '', id: '' },
        paymentError : ''
      };
    case actionTypes.PAYMENT_STATUS:
      return {
        ...state,
        status : action.payload
      };
    case actionTypes.PAYMENT_ERROR:
      return {
        ...state,
        paymentError  : action.payload,
        isUuidLoading : false
      };
    case actionTypes.COUPON_SERVICE_LOADING:
      return {
        ...state,
        isCouponLoading : true
      };
    case actionTypes.COUPON_SERVICE_SUCCESS:
      return {
        ...state,
        isCouponLoading : false,
        coupon          : action.payload,
        couponError     : undefined
      };
    case actionTypes.COUPON_SERVICE_ERROR:
      return {
        ...state,
        isCouponLoading : false,
        couponError     : action.payload
      };
    case actionTypes.CLEAR_COUPON_SERVICE:
      return {
        ...state,
        isCouponLoading : false,
        coupon          : undefined,
        couponError     : ''
      };
    case actionTypes.ADD_COUPON_SERVICE_SUCCESS:
      return {
        ...state,
        isCouponLoading : false,
        couponsAssigned : state.couponsAssigned + 1
      };
    case actionTypes.PAYMENT_GET_SERVICES_LOADING:
      return {
        ...state,
        isServicesLoading : true,
        servicesError     : undefined
      };
    case actionTypes.PAYMENT_GET_SERVICES_SUCCESS:
      return {
        ...state,
        isServicesLoading : false,
        paymentItems      : action.payload.paymentItems?.filter(
          ({ labor }) => !TRANSPORT_LABORS_SLUG.includes( labor.slug )
        ),
        total             : action.payload.total?.total,
        discounts         : action.payload.total?.discounts,
        laborDiscount     : action.payload.total?.laborDiscount,
        advance           : action.payload.total?.advance,
        subTotal          : action.payload.total?.subtotal,
        coupon            : undefined,
        serviceState      : action.payload.state,
        transportPrice    : action.payload.total?.transportPrice,
        transportDiscount : action.payload.total?.transportDiscount
      };
    case actionTypes.PAYMENT_GET_SERVICES_ERROR:
      return {
        ...state,
        isServicesLoading : false,
        servicesError     : action.payload
      };
    case actionTypes.PAYMENT_GET_SERVICE_PRICE_LOADING:
      return {
        ...state,
        isServicePriceLoading : true,
        servicePriceError     : ''
      };
    case actionTypes.PAYMENT_GET_SERVICE_PRICE_SUCCESS:
      return {
        ...state,
        isServicePriceLoading : false,
        servicePrice          : action.payload
      };
    case actionTypes.PAYMENT_GET_SERVICE_PRICE_ERROR:
      return {
        ...state,
        isServicePriceLoading : false,
        servicePriceError     : action.payload
      };
    case actionTypes.CLEAR_SERVICE_PRICE:
      return {
        ...state,
        isServicePriceLoading : false,
        servicePrice          : 0,
        servicePriceError     : '',
        couponsAssigned       : 0
      };
    case actionTypes.CLEAR_TOTALS:
      return {
        ...state,
        isServicePriceLoading : false,
        servicePrice          : 0,
        servicePriceError     : '',
        couponsAssigned       : 0,
        total                 : 0,
        discounts             : 0,
        laborDiscount         : 0,
        advance               : 0,
        transportPrice        : 0,
        transportDiscount     : 0,
        subTotal              : 0
      };
    default:
      return state;
  }
};
