import { sortServicesByStateHierarchy } from '../../helpers/serviceHelper';
import { sortPaymentMethodsBySelected } from '../../helpers/paymentMethodsHelper';
import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  session                           : undefined,
  user                              : undefined,
  isUpdatingUserLoading             : false,
  updatingUserError                 : '',
  updatedUserSuccess                : false,
  isAuthenticatedUserLoading        : false,
  authenticatedUserError            : '',
  authenticatedUserSuccess          : false,
  isSignInLoading                   : false,
  signInError                       : '',
  signInSuccess                     : false,
  isSignUpLoading                   : false,
  signUpError                       : '',
  signUpSuccess                     : false,
  isLoginResendCodeLoading          : false,
  loginResendCodeError              : '',
  loginResendCodeSuccess            : false,
  isSignOutLoading                  : false,
  signOutError                      : '',
  signOutSuccess                    : false,
  isConfirmationCodeVerifiedLoading : false,
  confirmationCodeVerifiedError     : '',
  confirmationCodeVerified          : false,
  sendRecoveryMailError             : '',
  sendRecoveryMailLoading           : false,
  sendRecoveryMailSuccess           : false,
  submitNewPasswordError            : '',
  submitNewPasswordLoading          : false,
  submitNewPasswordSuccess          : false,
  userError                         : '',
  vehicles                          : undefined,
  areVehiclesLoading                : false,
  vehiclesError                     : '',
  relatedVehicles                   : undefined,
  areRelatedVehiclesLoading         : false,
  relatedVehiclesError              : '',
  vehicle                           : undefined,
  isVehicleLoading                  : false,
  vehicleError                      : '',
  deleteVehicleSuccess              : false,
  isDeletingVehicle                 : false,
  deleteVehicleError                : '',
  addresses                         : undefined,
  areAddressesLoading               : false,
  addressesError                    : '',
  deleteAddressSuccess              : false,
  isDeletingAddress                 : false,
  deleteAddressError                : '',
  servicesHistory                   : undefined,
  servicesHistoryCount              : 0,
  servicesHistoryNext               : undefined,
  servicesHistoryPrevious           : undefined,
  isServicesHistoryLoading          : false,
  servicesHistoryError              : '',
  currentServices                   : undefined,
  areCurrentServicesLoading         : false,
  currentServicesError              : '',
  membership                        : undefined,
  isMembershipLoading               : false,
  membershipError                   : '',
  service                           : undefined,
  isServiceLoading                  : false,
  serviceError                      : '',
  membershipInvoices                : undefined,
  areMembershipInvoicesLoading      : false,
  membershipInvoicesError           : '',
  selectedPaymentMethod             : undefined,
  isSelectedPaymentMethodLoading    : false,
  selectedPaymentMethodError        : '',
  paymentMethods                    : undefined,
  arePaymentMethodsLoading          : false,
  paymentMethodsError               : '',
  isAddingPaymentMethod             : false,
  addPaymentMethodSuccess           : false,
  addPaymentMethodError             : '',
  isSelectingPaymentMethod          : false,
  selectPaymentMethodError          : undefined,
  selectPaymentMethodSuccess        : false,
  deletePaymentMethodSuccess        : false,
  isDeletingPaymentMethod           : false,
  deletePaymentMethodError          : '',
  coupon                            : undefined,
  couponError                       : '',
  couponLoading                     : false,
  serviceInvoice                    : false,
  isServiceInvoiceLoading           : false,
  serviceInvoiceError               : '',
  outstandingBalance                : false,
  isAddingAddress                   : false,
  addAddressError                   : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    /* ---- AUTH ----- */
    case actionTypes.RESTORE_SIGN_IN:
      return {
        ...state,
        isSignInLoading          : false,
        signInError              : '',
        signInSuccess            : false,
        isLoginResendCodeLoading : false,
        loginResendCodeError     : '',
        loginResendCodeSuccess   : false
      };
    case actionTypes.SIGN_IN_LOADING:
      return {
        ...state,
        isSignInLoading : action.payload,
        signInError     : ''
      };
    case actionTypes.SIGN_IN_ERROR:
      return {
        ...state,
        isSignInLoading : false,
        signInError     : action.payload
      };
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isSignInLoading : false,
        signInError     : '',
        signInSuccess   : true
      };
    case actionTypes.RESTORE_SIGN_UP:
      return {
        ...state,
        isSignUpLoading : false,
        signUpError     : '',
        signUpSuccess   : false
      };
    case actionTypes.SIGN_UP_LOADING:
      return {
        ...state,
        isSignUpLoading : action.payload,
        signUpError     : ''
      };
    case actionTypes.SIGN_UP_ERROR:
      return {
        ...state,
        isSignUpLoading : false,
        signUpError     : action.payload
      };
    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        isSignUpLoading : false,
        signUpError     : '',
        signUpSuccess   : true
      };
    case actionTypes.SIGN_UP_LOADING_RETRY:
      return {
        ...state,
        isSignUpLoadingRetry : action.payload,
        signUpErrorRetry     : '',
        signUpSuccessRetry   : false
      };
    case actionTypes.SIGN_UP_ERROR_RETRY:
      return {
        ...state,
        isSignUpLoadingRetry : false,
        signUpErrorRetry     : action.payload,
        signUpSuccessRetry   : false
      };
    case actionTypes.SIGN_UP_SUCCESS_RETRY:
      return {
        ...state,
        isSignUpLoadingRetry : false,
        signUpErrorRetry     : '',
        signUpSuccessRetry   : true
      };
    case actionTypes.SIGN_OUT_LOADING:
      return {
        ...state,
        isSignOutLoading : action.payload,
        signOutError     : ''
      };
    case actionTypes.SIGN_OUT_ERROR:
      return {
        ...state,
        isSignOutLoading : false,
        signOutError     : action.payload
      };
    case actionTypes.SIGN_OUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        isSignOutLoading : false,
        signOutError     : '',
        signOutSuccess   : true
      };
    case actionTypes.RESTORE_CONFIRMATION_CODE_VERIFIED:
      return {
        ...state,
        isConfirmationCodeVerifiedLoading : false,
        confirmationCodeVerifiedError     : '',
        confirmationCodeVerified          : false
      };
    case actionTypes.GET_VERIFICATION_CODE_LOADING:
      return {
        ...state,
        isConfirmationCodeVerifiedLoading : action.payload,
        confirmationCodeVerifiedError     : '',
        confirmationCodeVerified          : false
      };
    case actionTypes.GET_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        isConfirmationCodeVerifiedLoading : false,
        confirmationCodeVerifiedError     : action.payload
      };
    case actionTypes.GET_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        isConfirmationCodeVerifiedLoading : false,
        confirmationCodeVerifiedError     : '',
        confirmationCodeVerified          : true
      };
    case actionTypes.SIGN_IN_RESEND_CODE_LOADING:
      return {
        ...state,
        isLoginResendCodeLoading : action.payload,
        loginResendCodeError     : ''
      };
    case actionTypes.SIGN_IN_RESEND_CODE_ERROR:
      return {
        ...state,
        isLoginResendCodeLoading : false,
        logloginResendCodeError  : action.payload
      };
    case actionTypes.SIGN_IN_RESEND_CODE_SUCCESS:
      return {
        ...state,
        isLoginResendCodeLoading : false,
        loginResendCodeError     : '',
        loginResendCodeSuccess   : true
      };
    case actionTypes.GET_AUTHENTICATED_USER_LOADING:
      return {
        ...state,
        isAuthenticatedUserLoading : true,
        authenticatedUserError     : ''
      };
    case actionTypes.GET_AUTHENTICATED_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        outstandingBalance         : action.payload.user.outstandingBalance,
        isAuthenticatedUserLoading : false,
        authenticatedUserError     : '',
        authenticatedUserSuccess   : true
      };
    case actionTypes.GET_AUTHENTICATED_USER_ERROR:
      return {
        ...state,
        authenticatedUserError     : action.payload,
        isAuthenticatedUserLoading : false,
        authenticatedUserSuccess   : false
      };
    case actionTypes.SEND_RECOVERY_MAIL_LOADING:
      return {
        ...state,
        sendRecoveryMailLoading : true,
        sendRecoveryMailError   : '',
        sendRecoveryMailSuccess : false
      };
    case actionTypes.SEND_RECOVERY_MAIL_SUCCESS:
      return {
        ...state,
        sendRecoveryMailLoading : false,
        sendRecoveryMailError   : '',
        sendRecoveryMailSuccess : true
      };
    case actionTypes.SEND_RECOVERY_MAIL_ERROR:
      return {
        ...state,
        sendRecoveryMailError   : action.payload,
        sendRecoveryMailLoading : false,
        sendRecoveryMailSuccess : false
      };
    case actionTypes.SUBMIT_NEW_PASSWORD_LOADING:
      return {
        ...state,
        submitNewPasswordLoading : true,
        submitNewPasswordError   : '',
        submitNewPasswordSuccess : false
      };
    case actionTypes.SUBMIT_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        submitNewPasswordLoading : false,
        submitNewPasswordError   : '',
        submitNewPasswordSuccess : true
      };
    case actionTypes.SUBMIT_NEW_PASSWORD_ERROR:
      return {
        ...state,
        submitNewPasswordError   : action.payload,
        submitNewPasswordLoading : false,
        submitNewPasswordSuccess : false
      };
    case actionTypes.RESTORE_RECOVER_PASSWORD:
      return {
        ...state,
        submitNewPasswordError   : '',
        submitNewPasswordLoading : false,
        submitNewPasswordSuccess : false,
        sendRecoveryMailError    : '',
        sendRecoveryMailLoading  : false,
        sendRecoveryMailSuccess  : false
      };
    /* ---- USER ----- */
    case actionTypes.RESTORE_UPDATE_USER:
      return {
        ...state,
        isUpdatingUserLoading : false,
        updatingUserError     : '',
        updatedUserSuccess    : false
      };
    case actionTypes.UPDATE_USER_LOADING:
      return {
        ...state,
        isUpdatingUserLoading : true,
        updatingUserError     : '',
        updatedUserSuccess    : false
      };
    case actionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isUpdatingUserLoading : false,
        updatingUserError     : action.payload,
        updatedUserSuccess    : false
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUserLoading : false,
        updatingUserError     : '',
        updatedUserSuccess    : true,
        user                  : {
          ...state.user,
          ...action.payload
        },
        outstandingBalance : action.payload.outstandingBalance
      };
    /* ---- CURRENT SERVICES ----- */
    case actionTypes.GET_CURRENT_SERVICES_LOADING:
      return {
        ...state,
        areCurrentServicesLoading : true,
        currentServicesError      : ''
      };
    case actionTypes.GET_CURRENT_SERVICES_SUCCESS: {
      action.payload.forEach( ( s ) => {
        // eslint-disable-next-line no-param-reassign
        s.scheduleDate = new Date( s.scheduleDate );
      });

      return {
        ...state,
        currentServices           : sortServicesByStateHierarchy( action.payload ),
        areCurrentServicesLoading : false,
        currentServicesError      : ''
      };
    }
    case actionTypes.GET_CURRENT_SERVICES_ERROR:
      return {
        ...state,
        areCurrentServicesLoading : false,
        currentServicesError      : action.payload
      };
    case actionTypes.ADD_CURRENT_SERVICE: {
      // Check https://eslint.org/docs/rules/no-case-declarations for understanding
      // braces in this case
      const newService = action.payload;
      newService.scheduleDate = new Date( newService.scheduleDate );
      const newCurrentServices = state.currentServices
       ? [ ...state.currentServices, newService ]
       : [ newService ];

      return {
        ...state,
        currentServices : newCurrentServices
      };
    }
    case actionTypes.UPDATE_CURRENT_SERVICE: {
      const newDate = new Date( action.payload.scheduleDate );
      return {
        ...state,
        currentServices : state.currentServices?.map(
          ( ( service ) => ( service.id === action.payload.id
            ? { ...action.payload, scheduleDate: newDate }
            : service ) )
        )
      };
    }
    case actionTypes.REMOVE_CURRENT_SERVICE: {
      return {
        ...state,
        currentServices : state.currentServices?.filter(
          ( ( service ) => service.uuid !== action.payload )
        )
      };
    }

    /* ---- SERVICES HISTORY ----- */
    case actionTypes.GET_SERVICES_HISTORY_LOADING:
      return {
        ...state,
        isServicesHistoryLoading : true,
        servicesHistoryError     : ''
      };
    case actionTypes.GET_SERVICES_HISTORY_SUCCESS: {
      const servicesHistory = action.payload.results.map( ( service ) => ({
        ...service,
        scheduleDate : new Date( service.scheduleDate ),
        user         : state.user
      }) );

      return {
        ...state,
        servicesHistory,
        isServicesHistoryLoading : false,
        servicesHistoryError     : '',
        servicesHistoryCount     : action.payload.count,
        servicesHistoryNext      : action.payload.next,
        servicesHistoryPrevious  : action.payload.previous
      };
    }
    case actionTypes.GET_SERVICES_HISTORY_ERROR:
      return {
        ...state,
        isServicesHistoryLoading : false,
        servicesHistoryError     : action.payload
      };
    /* ---- RELATED VEHICLES ----- */
    case actionTypes.GET_RELATED_VEHICLES_LOADING:
      return {
        ...state,
        areRelatedVehiclesLoading : true,
        relatedVehiclesError      : ''
      };
    case actionTypes.GET_RELATED_VEHICLES_SUCCESS:
      return {
        ...state,
        relatedVehicles           : action.payload,
        areRelatedVehiclesLoading : false,
        relatedVehiclesError      : ''
      };
    case actionTypes.GET_RELATED_VEHICLES_ERROR:
      return {
        ...state,
        areRelatedVehiclesLoading : false,
        relatedVehiclesError      : action.payload
      };
    case actionTypes.ADD_RELATED_VEHICLE:
      return {
        ...state,
        relatedVehicles : [ ...state.relatedVehicles, action.payload ]
      };
    /* ---- VEHICLES ----- */
    case actionTypes.GET_VEHICLES_LOADING:
      return {
        ...state,
        areVehiclesLoading : true,
        vehiclesError      : ''
      };
    case actionTypes.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles           : action.payload,
        areVehiclesLoading : false,
        vehiclesError      : ''
      };
    case actionTypes.GET_VEHICLES_ERROR:
      return {
        ...state,
        areVehiclesLoading : false,
        vehiclesError      : action.payload
      };
    /* --- VEHICLE ---*/
    case actionTypes.GET_VEHICLE_LOADING:
      return {
        ...state,
        isVehicleLoading : true,
        vehicleError     : ''
      };
    case actionTypes.GET_VEHICLE_SUCCESS:
      return {
        ...state,
        isVehicleLoading : false,
        vehicleError     : '',
        vehicle          : action.payload
      };
    case actionTypes.GET_VEHICLE_ERROR:
      return {
        ...state,
        isVehicleLoading : false,
        vehicleError     : action.payload
      };
    case actionTypes.RESTORE_VEHICLE_DETAIL:
      return {
        ...state,
        isVehicleLoading : false,
        vehicleError     : '',
        vehicle          : undefined
      };
    case actionTypes.DELETE_VEHICLE_LOADING:
      return {
        ...state,
        isDeletingVehicle  : true,
        deleteVehicleError : ''
      };
    case actionTypes.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        deleteVehicleSuccess : true,
        isDeletingVehicle    : false,
        deleteVehicleError   : ''
      };
    case actionTypes.DELETE_VEHICLE_ERROR:
      return {
        ...state,
        isDeletingVehicle  : false,
        deleteVehicleError : action.payload
      };
    /* ---- ADDRESSES ----- */
    case actionTypes.GET_ADDRESSES_LOADING:
      return {
        ...state,
        areAddressesLoading : true,
        addressesError      : ''
      };
    case actionTypes.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses           : action.payload,
        areAddressesLoading : false,
        addressesError      : ''
      };
    case actionTypes.GET_ADDRESSES_ERROR:
      return {
        ...state,
        areAddressesLoading : false,
        addressesError      : action.payload
      };
    case actionTypes.ADD_ADDRESS_LOADING:
      return {
        ...state,
        isAddingAddress : true,
        addAddressError : ''
      };
    case actionTypes.ADD_ADDRESS:
      return {
        ...state,
        addresses       : [ ...state.addresses, action.payload ],
        isAddingAddress : false,
        addAddressError : ''
      };
    case actionTypes.ADD_ADDRESS_ERROR:
      return {
        ...state,
        isAddingAddress : false,
        addAddressError : action.payload
      };
    case actionTypes.DELETE_ADDRESS_LOADING:
      return {
        ...state,
        isDeletingAddress  : true,
        deleteAddressError : ''
      };
    case actionTypes.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteAddressSuccess : true,
        isDeletingAddress    : false,
        deleteAddressError   : ''
      };
    case actionTypes.DELETE_ADDRESS_ERROR:
      return {
        ...state,
        isDeletingAddress  : false,
        deleteAddressError : action.payload
      };
    /* ---- MEMBERSHIP ----- */
    case actionTypes.CREATE_MEMBERSHIP_LOADING:
      return {
        ...state,
        isMembershipLoading : true,
        membershipError     : ''
      };
    case actionTypes.CREATE_MEMBERSHIP_SUCCESS: {
      const newMembership = action.payload;

      // Parse dates of new membership
      newMembership.expirationDate = new Date( newMembership.expirationDate );
      // nextBillingDate is undefined for non-renewable memberships
      newMembership.nextBillingDate = newMembership.nextBillingDate
        ? new Date( newMembership.nextBillingDate )
        : undefined;

      return {
        ...state,
        membership          : action.payload,
        isMembershipLoading : false,
        membershipError     : '',
        user                : {
          ...state.user,
          memberships : [ ...state.user.memberships, newMembership ]
        }
      };
    }
    case actionTypes.CREATE_MEMBERSHIP_ERROR:
      return {
        ...state,
        isMembershipLoading : false,
        membershipError     : action.payload
      };
    /* ---- SERVICE ----- */
    case actionTypes.CREATE_SERVICE_LOADING:
      return {
        ...state,
        isServiceLoading : true,
        serviceError     : ''
      };
    case actionTypes.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        service          : action.payload,
        isServiceLoading : false,
        serviceError     : ''
      };
    case actionTypes.CREATE_SERVICE_ERROR:
      return {
        ...state,
        isServiceLoading : false,
        serviceError     : action.payload
      };
    case actionTypes.CLEAN_SERVICE_ERROR:
      return {
        ...state,
        serviceError : ''
      };
    /* ---- SERVICE INVOICE ----- */
    case actionTypes.RESTORE_SERVICE_INVOICE:
      return {
        ...state,
        isServiceInvoiceLoading : false,
        serviceInvoiceError     : '',
        serviceInvoice          : false
      };
    case actionTypes.CREATE_SERVICE_INVOICE_LOADING:
      return {
        ...state,
        isServiceInvoiceLoading : true,
        serviceInvoiceError     : ''
      };
    case actionTypes.CREATE_SERVICE_INVOICE_SUCCESS:
      return {
        ...state,
        serviceInvoice          : action.payload,
        isServiceInvoiceLoading : false,
        serviceInvoiceError     : ''
      };
    case actionTypes.CREATE_SERVICE_INVOICE_ERROR:
      return {
        ...state,
        isServiceInvoiceLoading : false,
        serviceInvoiceError     : action.payload
      };
    /* ---- MEMBERSHIP INVOICES ----- */
    case actionTypes.GET_MEMBERSHIP_INVOICES_LOADING:
      return {
        ...state,
        areMembershipInvoicesLoading : true,
        membershipInvoicesError      : ''
      };
    case actionTypes.GET_MEMBERSHIP_INVOICES_SUCCESS: {
      const newMembershipInvoices = action.payload.map( ( invoice ) => ({
        ...invoice,
        createdAt : new Date( invoice.createdAt ),
        updatedAt : new Date( invoice.updatedAt )
      }) );

      return {
        ...state,
        membershipInvoices           : newMembershipInvoices,
        areMembershipInvoicesLoading : false,
        membershipInvoicesError      : ''
      };
    }
    case actionTypes.GET_MEMBERSHIP_INVOICES_ERROR:
      return {
        ...state,
        areMembershipInvoicesLoading : false,
        membershipInvoicesError      : action.payload
      };
    /* ---- PAYMENT METHODS ----- */
    case actionTypes.GET_SELECTED_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        isSelectedPaymentMethodLoading : true,
        selectedPaymentMethodError     : ''
      };
    case actionTypes.GET_SELECTED_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        selectedPaymentMethod          : action.payload,
        isSelectedPaymentMethodLoading : false,
        selectedPaymentMethodError     : ''
      };
    case actionTypes.GET_SELECTED_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        isSelectedPaymentMethodLoading : false,
        selectedPaymentMethodError     : action.payload
      };
    case actionTypes.ADD_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        addPaymentMethodSuccess    : false,
        selectPaymentMethodSuccess : false,
        isAddingPaymentMethod      : true,
        addPaymentMethodError      : ''
      };
    case actionTypes.ADD_PAYMENT_METHOD_SUCCESS: {
      const newPaymentMethods = [ ...state.paymentMethods, action.payload ];
      const parsedPaymentMethods = newPaymentMethods.map( ( selectedPaymentMethod ) => ({
        ...selectedPaymentMethod,
        selected : selectedPaymentMethod.uuid === action.payload.uuid
      }) );

      return {
        ...state,
        paymentMethods          : sortPaymentMethodsBySelected( parsedPaymentMethods ),
        addPaymentMethodSuccess : true,
        isAddingPaymentMethod   : false,
        addPaymentMethodError   : '',
        selectedPaymentMethod   : action.payload
      };
    }
    case actionTypes.ADD_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        addPaymentMethodSuccess : false,
        isAddingPaymentMethod   : false,
        addPaymentMethodError   : action.payload
      };
    case actionTypes.GET_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        selectPaymentMethodSuccess : false,
        arePaymentMethodsLoading   : true,
        paymentMethodsError        : ''
      };
    case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods           : sortPaymentMethodsBySelected( action.payload ),
        arePaymentMethodsLoading : false,
        paymentMethodsError      : ''
      };
    case actionTypes.GET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        arePaymentMethodsLoading : false,
        paymentMethodsError      : action.payload
      };
    case actionTypes.SELECT_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        addPaymentMethodSuccess    : false,
        selectPaymentMethodSuccess : false,
        isSelectingPaymentMethod   : true,
        selectPaymentMethodError   : ''
      };
    case actionTypes.SELECT_PAYMENT_METHOD_SUCCESS: {
      // Change the paymentMethods by selecting the new one and sorting them by "selected" attribute
      const parsedPaymentMethods = state.paymentMethods.map( ( selectedPaymentMethod ) => ({
        ...selectedPaymentMethod,
        selected : selectedPaymentMethod.uuid === action.payload.uuid
      }) );

      return {
        ...state,
        paymentMethods             : parsedPaymentMethods,
        selectedPaymentMethod      : action.payload,
        selectPaymentMethodSuccess : true,
        isSelectingPaymentMethod   : false,
        selectPaymentMethodError   : ''
      };
    }
    case actionTypes.SELECT_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        isSelectingPaymentMethod   : false,
        selectPaymentMethodSuccess : false,
        selectPaymentMethodError   : action.payload
      };
    case actionTypes.DELETE_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        isDeletingPaymentMethod  : true,
        deletePaymentMethodError : ''
      };
    case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        deletePaymentMethodSuccess : true,
        isDeletingPaymentMethod    : false,
        deletePaymentMethodError   : ''
      };
    case actionTypes.DELETE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        isDeletingPaymentMethod  : false,
        deletePaymentMethodError : action.payload
      };
    /* ---- COUPON ----- */
    case actionTypes.CREATE_COUPON_LOADING:
      return {
        ...state,
        coupon        : undefined,
        couponError   : '',
        couponLoading : true
      };
    case actionTypes.CREATE_COUPON_SUCCESS:
      return {
        ...state,
        coupon        : action.payload,
        couponError   : '',
        couponLoading : false
      };
    case actionTypes.CREATE_COUPON_ERROR:
      return {
        ...state,
        coupon        : undefined,
        couponError   : action.payload,
        couponLoading : false
      };
    /* ---- DEFAULT CASE ----- */
    default:
      return state;
  }
};
