import { Auth } from 'aws-amplify';
import { getGeneralHandleError } from '../../utils/apiErrors';


async function getHeaders() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const jwt = accessToken.getJwtToken();
  const headers = new Headers();
  headers.append( 'Content-Type', 'application/json' );
  headers.append( 'Authorization', `Bearer ${ jwt }` );
  return headers;
}

const baseUrl = process.env.REACT_APP_FETCH_URL;
const trackingUrl = process.env.REACT_APP_FETCH_TRACKING_URL;

export async function get( path, keepUrl ) {
  const fullUrl = keepUrl ? path : `${ baseUrl }/${ path }`;
  const headers = await getHeaders();
  const response = await fetch( fullUrl, { method: 'GET', headers });
  if( response.ok ) {
    return response.json();
  }
  return getGeneralHandleError( response );
}

export async function post( path, body ) {
  const fullUrl = `${ baseUrl }/${ path }`;
  const headers = await getHeaders();
  const response = await fetch( fullUrl, { method: 'POST', headers, body });
  if( response.ok ) {
    return response.json();
  }
  return getGeneralHandleError( response );
}

export async function patch( path, body ) {
  const fullUrl = `${ baseUrl }/${ path }`;
  const headers = await getHeaders();
  const response = await fetch( fullUrl, { method: 'PATCH', headers, body });
  if( response.ok ) {
    return response.json();
  }
  return getGeneralHandleError( response );
}

export async function getAllData( url, keepUrl ) {
  const json = await get( url, keepUrl );
  let { results } = json;
  if( json.next ) {
    const nextJson = await getAllData( json.next, true );
    results = [ ...results, ...nextJson.results ];
  }
  json.results = results;
  return json;
}

export async function del( path, body ) {
  const fullUrl = `${ baseUrl }/${ path }`;
  const headers = await getHeaders();
  const response = await fetch( fullUrl, { method: 'DELETE', headers, body });
  if( response.ok ) {
    return response.json();
  }
  return getGeneralHandleError( response );
}


export async function publicGet( path, keepUrl ) {
  const fullUrl = keepUrl ? path : `${ trackingUrl }/${ path }`;
  const response = await fetch( fullUrl, { method: 'GET' });
  if( response.ok ) {
    return response.json();
  }
  return getGeneralHandleError( response );
}
