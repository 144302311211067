import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { icons } from '@alfred-co/alfred-react-components';

import useNotFoundStyles from './trackingNotFoundStyles';
import { homePath } from '../../../utils/paths';

const { AlfredLogo } = icons;


function getRedirectMessage( classes, message ) {
  return (
    <>
      <Typography variant="h4" color="primary" align="center">
        { message }
      </Typography>
      <Typography variant="body1" color="primary" align="center">
        Serás redireccionado a nuestra página en 10 segundos.
      </Typography>
    </>
  );
}

export default function TrackingNotFound() {
  const classes = useNotFoundStyles();
  const navigate = useNavigate();

  useEffect( () => {
    const timer = setTimeout( () => {
      navigate( homePath );
    }, 10000 );
    return () => clearTimeout( timer );
  }, [ navigate ] );

  return (
    <Grid
      container
      spacing={ 0 }
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={ classes.trackingNotFound }
    >
      <img src={ AlfredLogo } alt="logo" className={ classes.TrackingLogo } />

      {
        getRedirectMessage( classes, '¡El servicio que buscas no está disponible! escríbenos a nuestros canales oficiales para más información.' )
      }
    </Grid>
  );
}
