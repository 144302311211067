import { combineReducers } from 'redux';

import userReducer from './userReducer';
import cityReducer from './cityReducer';
import laborReducer from './laborReducer';
import merchantReducer from './merchantReducer';
import paymentReducer from './paymentReducer';
import requestReducer from './requestReducer';
import daysOffReducer from './daysOffReducer';
import categoryReducer from './categoryReducer';
import trackingReducer from './trackingReducer';
import shopReducer from './shopReducer';
import vehicleReducer from './vehicleReducer';


export default combineReducers({
  userReducer,
  cityReducer,
  laborReducer,
  merchantReducer,
  paymentReducer,
  requestReducer,
  daysOffReducer,
  categoryReducer,
  trackingReducer,
  shopReducer,
  vehicleReducer
});
