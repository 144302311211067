import { makeStyles } from '@material-ui/core';


const useTrackingTimeLineStyles = makeStyles(
  ( theme ) => ({
    trackingCustomDialog : {
      overflow : 'hidden'
    },
    trackingEventEventContainer : {
      marginTop : theme.margin( 2 )
    },
    trackingEventEventName : {
      marginLeft : theme.margin( 2 )
    },
    trackingEventIconContainer : {
      cursor     : 'pointer',
      marginLeft : theme.margin( 4 ),
      marginTop  : theme.margin( 2 )
    },
    trackingEventImageText : {
      paddingLeft : theme.padding( 1 )
    },
    trackingEventImageContainer : {
      width      : '100%',
      height     : '100%',
      background : theme.palette.primary.dark,
      padding    : `${ theme.padding( 0 ) } ${ theme.padding( 2 ) }`
    },
    trackingEventImage : {
      width     : '100%',
      maxHeight : '100%',
      objectFit : 'contain'
    },
    trackingEventVideo : {
      width  : '100%',
      height : '90%'
    },
    trackingEventSliderContainer : {
      height : '80vh',
      width  : '100%',

      '& .swiper-pagination-bullet-active' : {
        backgroundColor : theme.palette.primary.main
      },
      '& .makeStyles-customDialogContent' : {
        overflowY : 'hidden'
      }
    }

  })
);
export default useTrackingTimeLineStyles;
