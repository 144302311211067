import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  categories           : undefined,
  areCategoriesLoading : false,
  categoriesError      : '',
  category             : undefined,
  isCategoryLoading    : false,
  categoryError        : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_CATEGORIES_LOADING:
      return {
        ...state,
        areCategoriesLoading : true,
        categoriesError      : ''
      };
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories           : action.payload,
        areCategoriesLoading : false,
        categoriesError      : ''
      };
    case actionTypes.GET_CATEGORIES_ERROR:
      return {
        ...state,
        areCategoriesLoading : false,
        categoriesError      : action.payload
      };
    case actionTypes.GET_CATEGORY_LOADING:
      return {
        ...state,
        isCategoryLoading : true,
        categoryError     : ''
      };
    case actionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category          : action.payload,
        isCategoryLoading : false,
        categoryError     : ''
      };
    case actionTypes.GET_CATEGORY_ERROR:
      return {
        ...state,
        isCategoryLoading : false,
        categoryError     : action.payload
      };

    default:
      return state;
  }
};
