import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  merchant          : undefined,
  isMerchantLoading : false,
  merchantError     : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    // ---- GET MERCHANT ----- //
    case actionTypes.GET_MERCHANT_LOADING:
      return {
        ...state,
        isMerchantLoading : true,
        merchantError     : ''
      };
    case actionTypes.GET_MERCHANT_SUCCESS:
      return {
        ...state,
        merchant          : action.payload,
        isMerchantLoading : false,
        merchantError     : ''
      };
    case actionTypes.GET_MERCHANT_ERROR:
      return {
        ...state,
        isMerchantLoading : false,
        merchantError     : action.payload
      };
    default:
      return state;
  }
};
