import { find, orderBy } from 'lodash';
import { constants } from '@alfred-co/alfred-react-components';

const { SERVICE_STATUS, TRANSPORT_LABORS_SLUG } = constants;
const {
  IN_PROGRESS,
  COMPLETED,
  REQUESTED,
  TO_BE_PAID
} = SERVICE_STATUS;

export function unifyTransport( serviceLabors ) {
  const {
    paymentLabors,
    transportPrice,
    transportDiscount
  } = serviceLabors.reduce( ( result, item ) => {
    const { discount, price, labor } = item;
    const { slug } = labor;
    if( TRANSPORT_LABORS_SLUG.includes( slug ) ) {
      return {
        ...result,
        transportPrice    : result.transportPrice + Number( price ),
        transportDiscount : result.transportDiscount + Number( discount )
      };
    }
    return {
      ...result,
      paymentLabors : [ ...result.paymentLabors, item ]
    };
  }, { paymentLabors: [], transportPrice: 0, transportDiscount: 0 });

  return { paymentLabors, transportPrice, transportDiscount };
}

export function sortServicesByScheduleDateDescendent( services ) {
  return orderBy( services, [ ( service ) => Date.parse( service.scheduleDate ) ], [ 'desc' ] );
}

export function sortServicesByStateHierarchy( services ) {
  const sortedServicesByDate = sortServicesByScheduleDateDescendent( services );
  return orderBy( sortedServicesByDate, [ ( service ) => ( service.state === IN_PROGRESS ),
    ( service ) => ( service.state === REQUESTED ) ], [ 'desc', 'desc' ] );
}

export function sortServicesFromToPayToCompleted( services ) {
  const sortedServicesByDate = sortServicesByScheduleDateDescendent( services );
  return orderBy( sortedServicesByDate, [
    ( service ) => (
      service.state === TO_BE_PAID
        && service.payingCustomer?.uuid === service.user.uuid
    ),
    ( service ) => (
      service.state === COMPLETED
        && !service.isPaid
        && service.payingCustomer?.uuid === service.user.uuid
    )
  ], [ 'desc', 'desc' ] );
}

export function findServiceLabor( labors, labor ) {
  const findLaborWithPrice = labors?.laborsWithPrice && find( labors.laborsWithPrice,
    ( l ) => l.slug === labor );
  const findLaborWithOutPrice = labors?.laborsWithOutPrice && find( labors.laborsWithOutPrice,
    ( l ) => l.slug === labor );

  const findLabor = findLaborWithPrice || findLaborWithOutPrice;
  const laborWithOptions = findLabor && findLabor.config?.data?.options;
  let serviceLabor = findLabor && [ { labor: findLabor } ];
  if( laborWithOptions ) {
    let checkedInitialItems = {};
    checkedInitialItems = Object.keys( findLabor.config.data.options ).reduce(
      ( result, option ) => {
        const items = result;
        items[ option ] = false;
        return items;
      }, {}
    );
    serviceLabor = [ { labor: findLabor, data: checkedInitialItems } ];
  }

  return serviceLabor;
}
