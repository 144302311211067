import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  labors                          : undefined,
  areLaborsLoading                : false,
  laborsError                     : '',
  laborDetail                     : undefined,
  isLaborDetailLoading            : true,
  laborDetailError                : '',
  laborsWithoutCategory           : undefined,
  areLaborsWithoutCategoryLoading : false,
  laborsWithoutCategoryError      : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    // ---- GET LABORS ----- //
    case actionTypes.GET_LABORS_LOADING:
      return {
        ...state, areLaborsLoading : true, laborsError : ''
      };
    case actionTypes.GET_LABORS_SUCCESS:
      return {
        ...state, labors : action.payload, areLaborsLoading : false, laborsError : ''
      };
    case actionTypes.GET_LABORS_ERROR:
      return { ...state, areLaborsLoading: false, laborsError: action.payload };
    case actionTypes.GET_LABOR_DETAIL_LOADING:
      return { ...state, isLaborDetailLoading: true, laborDetailError: '' };
    case actionTypes.GET_LABOR_DETAIL_SUCCESS:
      return {
        ...state,
        isLaborDetailLoading : false,
        laborDetailError     : '',
        laborDetail          : action.payload
      };
    case actionTypes.GET_LABOR_DETAIL_ERROR:
      return {
        ...state,
        isLaborDetailLoading : false,
        laborDetailError     : action.payload
      };
    case actionTypes.RESTORE_SERVICE_DETAIL:
      return {
        ...state,
        isLaborDetailLoading : true,
        laborDetailError     : '',
        laborDetail          : undefined
      };
    case actionTypes.GET_LABORS_WITHOUT_CATEGORY_LOADING:
      return {
        ...state,
        areLaborsWithoutCategoryLoading : true,
        laborsWithoutCategoryError      : ''
      };
    case actionTypes.GET_LABORS_WITHOUT_CATEGORY_SUCCESS:
      return {
        ...state,
        laborsWithoutCategory           : action.payload,
        areLaborsWithoutCategoryLoading : false,
        laborsWithoutCategoryError      : ''
      };
    case actionTypes.GET_LABORS_WITHOUT_CATEGORY_ERROR:
      return {
        ...state,
        areLaborsWithoutCategoryLoading : false,
        laborsWithoutCategoryError      : action.payload
      };
    default:
      return state;
  }
};
