import { constants } from '@alfred-co/alfred-react-components';
import { get, post } from './BaseService';

const { SERVICE_STATUS } = constants;
const {
  IN_PROGRESS,
  TO_BE_CONFIRMED,
  REQUESTED,
  COMPLETED,
  CANCELED,
  TO_BE_PAID
} = SERVICE_STATUS;

async function createService( service ) {
  return post( `vehicles/${ service.vehicle.uuid }/services`, JSON.stringify( service ) );
}

async function getServiceHistory( client, limit, page ) {
  if( page ) {
    return get( page.split( '/v2/' )[ 1 ] );
  }
  return get( `clients/${ client.uuid }/services?ordering=-schedule_date${ !limit ? '' : `&limit=${ limit }` }&expand=service_labors&state=${ COMPLETED },${ CANCELED },${ TO_BE_PAID }&fields=isPaid,uuid,scheduleDate,vehicle,state,startAddress,endAddress,serviceLabors` );
}

async function getCurrentServices( client ) {
  return get( `clients/${ client.uuid }/services?state=${ IN_PROGRESS },${ TO_BE_CONFIRMED },${ REQUESTED }&fields=id,uuid,scheduleDate,state,vehicle` );
}

async function getServiceDetail( client, serviceId ) {
  return get( `clients/${ client.uuid }/services/${ serviceId }?fields=service_labors` );
}

async function payService( serviceId ) {
  return post( `payment/service/${ serviceId }` );
}

async function getServiceInvoice( serviceId, invoiceId ) {
  return get( `services/${ serviceId }/invoices/${ invoiceId }` );
}

async function getServicePrice( serviceLabors, typeVehicle ) {
  return post( 'services/calculate-full-price', JSON.stringify({ serviceLabors, typeVehicle }) );
}

export default {
  createService,
  getServiceHistory,
  getCurrentServices,
  getServiceDetail,
  payService,
  getServiceInvoice,
  getServicePrice
};
