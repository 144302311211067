import { makeStyles } from '@material-ui/core';


const useTrackingStyles = makeStyles(
  ( theme ) => ({
    trackingContainer : {
      background : theme.palette.primary.dark,
      minHeight  : '100vh',

      [ theme.breakpoints.up( 'md' ) ] : {
        background : `linear-gradient(90deg, ${ theme.palette.primary.dark } 50%, #fff 50%)`,
        margin     : 'auto'
      }
    },
    trackingLeftContainer : {
      maxHeight : '100vh',
      overflowY : 'auto'
    },
    trackingLeftInfo : {
      minHeight  : '420px',
      '& iframe' : {
        minHeight : '80vh',
        width     : 'fill-available'
      },
      [ theme.breakpoints.up( 'md' ) ] : {
        minHeight  : '100vh',
        maxWidth   : '720px',
        paddingTop : theme.padding( 6 ),
        marginLeft : theme.margin( 6 )
      }
    },
    trackingHeaderLogo : {
      padding                          : `${ theme.padding( 2 ) } ${ theme.padding( 4 ) } 0`,
      cursor                           : 'pointer',
      [ theme.breakpoints.up( 'sm' ) ] : {
        marginLeft : theme.margin( 2 ),
        padding    : '0',
        width      : '130px',
        height     : 'auto',
        '& img'    : {
          width : '100%'
        }

      }
    },
    headerChubbImg : {
      height      : '10px',
      paddingLeft : theme.padding( 2 )
    },
    headerChubbSection : {
      color       : theme.palette.primary.white,
      paddingLeft : theme.padding( 3 )
    },
    headerChubbText : {
      ...theme.typography.caption,
      color : theme.palette.primary.contrastText
    },
    trackingState : {
      paddingBottom : theme.padding( 2 ),

      [ theme.breakpoints.up( 'md' ) ] : {
        marginTop : theme.margin( 5 ),
        padding   : '0'
      }

    },
    trackingTimeLine : {
      marginTop : 0
    },
    trackingRightInfo : {
      '& iframe' : {
        minHeight : '100vh',
        width     : 'fill-available'
      },
      [ theme.breakpoints.down( 'sm' ) ] : {
        position        : 'fixed',
        bottom          : 0,
        backgroundColor : theme.palette.primary.dark,
        maxHeight       : '100%',
        overflow        : 'auto'
      }
    },
    trackingAcordion : {
      borderRadius                  : '0 !important',
      backgroundColor               : 'unset',
      boxShadow                     : 'none',
      width                         : '100%',
      '& .MuiAccordionDetails-root' : {
        backgroundColor : theme.palette.grey[ 600 ],
        color           : theme.palette.primary.contrastText,
        padding         : `${ theme.padding( 5 ) } ${ theme.padding( 2 ) }`,
        paddingBottom   : theme.padding( 1 )

      },
      '& .MuiAccordionSummary-root' : {

        '& .MuiSvgIcon-root' : {
          fill        : theme.palette.primary.main,
          marginRight : theme.margin( 1 )
        }
      }
    }
  })
);

export default useTrackingStyles;
