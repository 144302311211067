// Primary colors
export const COLOR_PRIMARY_DARK = '#111E3E';
export const COLOR_PRIMARY_MAIN = '#B1FC00';
export const COLOR_PRIMARY_LIGHT = '#0096FB';
export const COLOR_PRIMARY_LIGHTER = '#F2F9FE';
export const COLOR_PRIMARY_WHITE = '#ffffff';

// Secondary colors
export const COLOR_SECONDARY_DARK = '#020D20';
export const COLOR_SECONDARY_MAIN = '#19EB00';
export const COLOR_SECONDARY_LIGHT = '#0075C5';
export const COLOR_SECONDARY_LIGHTER = 'rgba(46,91,255,0.08)';

// Gray colors
export const COLOR_GREY_10 = '#FCFCFC';
export const COLOR_GREY_50 = '#F8FAFF';
export const COLOR_GREY_100 = '#F1F5FF';
export const COLOR_GREY_200 = '#DBE0EE';
export const COLOR_GREY_300 = '#BFC4D1';
export const COLOR_GREY_400 = '#AAAEB9';
export const COLOR_GREY_500 = '#83878F';
export const COLOR_GREY_600 = '#30364E';
export const COLOR_GREY_700 = '#35373A';

// Success colors
export const COLOR_SUCCESS_DARK = '#7BD03F';
export const COLOR_SUCCESS_MAIN = '#B4FB00';
export const COLOR_SUCCESS_LIGHT = '#ABFF70';

// Warning colors
export const COLOR_WARNING_DARK = '#C7A63A';
export const COLOR_WARNING_MAIN = '#F7CE46';
export const COLOR_WARNING_LIGHT = '#FFDE70';

// Error colors
export const COLOR_ERROR_DARK = '#BE3F38';
export const COLOR_ERROR_MAIN = '#EB4E45';
export const COLOR_ERROR_LIGHT = '#FF6F67';

// Info colors
export const COLOR_INFO_DARK = '#01568E';
export const COLOR_INFO_MAIN = '#007CCF';
export const COLOR_INFO_LIGHT = '#008EED';

// Gradients
export const GRADIENT_DARK = `linear-gradient(246.32deg, ${ COLOR_PRIMARY_DARK } 0%, ${ COLOR_SECONDARY_DARK } 99.64%)`;
export const GRADIENT_MAIN = `linear-gradient(98.1deg, ${ COLOR_PRIMARY_MAIN } 6.12%, ${ COLOR_SECONDARY_MAIN } 111.74%)`;
export const GRADIENT_LIGHT = `linear-gradient(90deg, ${ COLOR_PRIMARY_LIGHT } 0%, ${ COLOR_SECONDARY_LIGHT } 100%)`;
export const GRADIENT_OTHER_DARK = `linear-gradient(90deg, ${ COLOR_PRIMARY_DARK } 2.78%, rgba(17, 30 , 62, 0) 100%)`;
