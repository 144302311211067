export const homePath = '/';
export const requestServicePath = '/request';
export const confirmServicePath = `${ requestServicePath }/:uuid`;
export const membershipPath = '/membership';
export const paymentsPath = '/payments';
export const loginPath = '/login';
export const signUpPath = '/signup';
export const activatePath = '/activate';
export const recoverPath = '/recover';
export const servicePath = '/services';
export const vehiclePath = '/vehicle';
export const serviceDetailPath = `${ servicePath }/:uuid`;
export const vehicleDetailPath = `${ vehiclePath }/:uuid`;
export const page404Path = '*';
export const paymentPath = '/payment/new';
export const profilePath = '/profile';
export const profilePaymentPath = `${ profilePath }/payment`;
export const profileMembershipPath = `${ profilePath }/membership`;
export const trackPath = '/tracking';
export const trackServicePath = `${ trackPath }/:uuid`;
export const pendingPaymentsPath = '/pending-payments';
export const getAppPath = '/get-app';
