import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  daysOff                 : undefined,
  areDaysOffLoading       : false,
  daysOffError            : '',
  isBlockedSlotsLoading   : false,
  blockedSlots            : [],
  blockedSlotsError       : '',
  alfredSchedule          : null,
  isAlfredScheduleLoading : false,
  alfredScheduleError     : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_DAYSOFF_LOADING:
      return {
        ...state,
        areDaysOffLoading : true,
        daysOffError      : ''
      };
    case actionTypes.GET_DAYSOFF_SUCCESS:
      return {
        ...state,
        daysOff           : action.payload,
        areDaysOffLoading : false,
        daysOffError      : ''
      };
    case actionTypes.GET_DAYSOFF_ERROR:
      return {
        ...state,
        areDaysOffLoading : false,
        daysOffError      : action.payload
      };
    case actionTypes.GET_BLOCKED_SLOTS_LOADING:
      return {
        ...state,
        isBlockedSlotsLoading : true,
        blockedSlotsError     : ''
      };
    case actionTypes.GET_BLOCKED_SLOTS_SUCCESS:
      return {
        ...state,
        blockedSlots          : action.payload,
        isBlockedSlotsLoading : false,
        blockedSlotsError     : ''
      };
    case actionTypes.GET_BLOCKED_SLOTS_ERROR:
      return {
        ...state,
        isBlockedSlotsLoading : false,
        blockedSlotsError     : action.payload
      };
    case actionTypes.GET_ALFRED_SCHEDULE_LOADING:
      return {
        ...state,
        isAlfredScheduleLoading : true,
        alfredScheduleError     : ''
      };
    case actionTypes.GET_ALFRED_SCHEDULE_SUCCESS:
      return {
        ...state,
        alfredSchedule          : action.payload,
        isAlfredScheduleLoading : false,
        alfredScheduleError     : ''
      };
    case actionTypes.GET_ALFRED_SCHEDULE_ERROR:
      return {
        ...state,
        isAlfredScheduleLoading : false,
        alfredScheduleError     : action.payload
      };
    default:
      return state;
  }
};
