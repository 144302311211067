import {
  get,
  post,
  patch,
  del
} from './BaseService';

async function createPaymentMethod( client, paymentInfo, acceptanceToken ) {
  const body = {
    transactionToken  : paymentInfo.token,
    paymentMethodType : paymentInfo.type,
    brand             : paymentInfo.brand,
    name              : paymentInfo.cardHolder.substring( 0, 100 ),
    cardNumber        : paymentInfo.lastFour,
    acceptanceToken
  };
  return post( `clients/${ client.uuid }/payment-methods`, JSON.stringify( body ) );
}

async function getSelectedPaymentMethods( client ) {
  return get( `clients/${ client.uuid }/payment-methods?selected=true` );
}

async function getPaymentMethods( client ) {
  return get( `clients/${ client.uuid }/payment-methods` );
}

async function selectPaymentMethod( client, paymentMethodToSelect ) {
  return patch( `clients/${ client.uuid }/payment-methods/${ paymentMethodToSelect.uuid }`, JSON.stringify({ selected: true }) );
}

async function generateUuidPayment( serviceId ) {
  return post( `payment/service/${ serviceId }/get-reference ` );
}

async function paymentStatus( serviceId ) {
  return post( `payment/service/${ serviceId }/payment-result` );
}

async function deletePaymentMethod( client, paymentMethodToDelete ) {
  return del( `clients/${ client.uuid }/payment-methods/${ paymentMethodToDelete.uuid }` );
}

export default {
  createPaymentMethod,
  deletePaymentMethod,
  getSelectedPaymentMethods,
  getPaymentMethods,
  selectPaymentMethod,
  generateUuidPayment,
  paymentStatus
};
