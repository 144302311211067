import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CachedIcon from '@material-ui/icons/Cached';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { Splash, dateUtils } from '@alfred-co/alfred-react-components';

import useTrackingTimelineStyles from './trackingTimelineStyles';
import TrackingEvents from './TrackingEvents';
import TrackingImage from './TrackingImage';

const { toLongDateWithMinutes } = dateUtils;

const TrackingTimeline = () => {
  const classes = useTrackingTimelineStyles();
  const theme = useTheme();
  const { tracking } = useSelector( ( state ) => state.trackingReducer );

  const getShopOrAlfred = useCallback( ({ shop, alfred }) => {
    if( alfred ) {
      return `Alfred: ${ alfred?.firstName } ${ alfred?.lastName }`;
    }
    if( shop ) {
      return `${ shop?.name } | ${ shop?.address?.address }`;
    }
    return 'No asignado';
  }, [] );

  const timeLineIcon = useCallback( ({ startDate, endDate }) => {
    if( startDate ) {
      if( endDate ) {
        return <CheckIcon />;
      }
      return <CachedIcon />;
    }
    return <QueryBuilderIcon />;
  }, [] );


  const buildTimeLine = useCallback( ( serviceLabors ) => ( serviceLabors
    .map( ( serviceLabor, index ) => (
      <TimelineItem key={ `timeline-item-${ index }` } className={ classes.customTimeLineItem }>
        <TimelineOppositeContent color="text.secondary">
          <TrackingImage serviceLabor={ serviceLabor } />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            className={ classes.customTimelineDot }
            style={
              {
                background : serviceLabor?.startDate
                ? `-webkit-linear-gradient(98.1deg, ${ theme.palette.primary.main } 6.12%, ${ theme.palette.secondary.main } 111.74%)`
                : theme.palette.grey[ 400 ]
              }
            }
          >
            { timeLineIcon( serviceLabor ) }
          </TimelineDot>
          { index !== serviceLabors.length - 1 && <TimelineConnector /> }
        </TimelineSeparator>
        <TimelineContent className={ classes.customTimeLineContent }>
          <Grid container direction="row">
            <Grid
              item
              xs={ serviceLabor?.buttonText ? 8 : 12 }
              sm={ serviceLabor?.buttonText ? 10 : 12 }
            >
              <Typography variant="body1">
                { serviceLabor.labor?.laborConfigLabel || serviceLabor.labor?.name }
              </Typography>
              <Typography variant="body2" className={ classes.customTimeLineAlfredTime }>
                { getShopOrAlfred( serviceLabor ) }
                { ' | ' }
                { toLongDateWithMinutes( new Date( serviceLabor.scheduleDate ) ) }
              </Typography>
              <Grid>
                {
                  serviceLabor?.events?.length
                && <TrackingEvents events={ serviceLabor?.events } />
                }
              </Grid>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    ) )
  ), [ classes, getShopOrAlfred, theme, timeLineIcon ] );

  if( !tracking ) return <Splash />;

  return (
    <Timeline position="alternate" className={ classes.trackingTimeline }>
      { buildTimeLine( tracking?.serviceLabors ) }
    </Timeline>
  );
};

export default TrackingTimeline;
