import { translateAuthError, USER_NOT_CONFIRMED_EXCEPTION } from '../../../utils/authUtils';
import { identifyUserToTrack, setUserToTrackProperties } from '../../../utils/userTrackerUtils';
import authService from '../../services/AuthService';
import { clearRequestData } from '../request/requestActions';
import {
  GET_AUTHENTICATED_USER_LOADING,
  GET_AUTHENTICATED_USER_ERROR,
  GET_AUTHENTICATED_USER_SUCCESS,
  SIGN_IN_LOADING,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_UP_LOADING,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  GET_VERIFICATION_CODE_LOADING,
  GET_VERIFICATION_CODE_SUCCESS,
  GET_VERIFICATION_CODE_ERROR,
  SIGN_IN_RESEND_CODE_LOADING,
  SIGN_IN_RESEND_CODE_SUCCESS,
  SIGN_IN_RESEND_CODE_ERROR,
  SIGN_OUT_LOADING,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
  SEND_RECOVERY_MAIL_LOADING,
  SEND_RECOVERY_MAIL_SUCCESS,
  SEND_RECOVERY_MAIL_ERROR,
  SUBMIT_NEW_PASSWORD_LOADING,
  SUBMIT_NEW_PASSWORD_SUCCESS,
  SUBMIT_NEW_PASSWORD_ERROR,
  RESTORE_RECOVER_PASSWORD,
  RESTORE_SIGN_UP,
  RESTORE_SIGN_IN,
  RESTORE_CONFIRMATION_CODE_VERIFIED,
  SIGN_UP_SUCCESS_RETRY,
  SIGN_UP_LOADING_RETRY,
  SIGN_UP_ERROR_RETRY,
  COMPONENT_ERROR
} from '../types';


function getErrorMessage( error = {}) {
  const translatedError = error && error.code && translateAuthError( error );
  return ( translatedError && translatedError.message ) || error.message || error;
}

export const getAuthenticatedUser = () => async ( dispatch, getState ) => {
  try {
    let { session, user } = getState().userReducer;
    dispatch({
      type : GET_AUTHENTICATED_USER_LOADING
    });

    if( !session ) {
      session = await authService.getSession();
      identifyUserToTrack( session.attributes.email );
    }
    if( !user ) {
      user = await authService.getUser();
    }
    localStorage.setItem( 'userUuid', user.uuid );

    dispatch({
      type    : GET_AUTHENTICATED_USER_SUCCESS,
      payload : {
        user,
        session
      }
    });
  }
  catch ( error ) {
    dispatch({
      type    : GET_AUTHENTICATED_USER_ERROR,
      payload : getErrorMessage( error )
    });
  }
};

export const resendConfirmationCode = ( email ) => async ( dispatch ) => {
  try {
    dispatch({
      type : SIGN_IN_RESEND_CODE_LOADING
    });
    await authService.resendConfirmationCode( email );
    dispatch({
      type    : SIGN_IN_RESEND_CODE_SUCCESS,
      payload : true
    });
  }
  catch ( error ) {
    dispatch({
      type    : SIGN_IN_RESEND_CODE_ERROR,
      payload : getErrorMessage( error )
    });
  }
};

export const signInUser = ( email, password ) => async ( dispatch ) => {
  try {
    dispatch({
      type    : SIGN_IN_LOADING,
      payload : true
    });
    const formattedEmail = email.toLowerCase();
    const cognitoSignInUser = await authService.signIn( formattedEmail, password );

    identifyUserToTrack( cognitoSignInUser.attributes.email );
    await dispatch( getAuthenticatedUser() );
    dispatch({
      type    : SIGN_IN_SUCCESS,
      payload : true
    });
  }
  catch ( error ) {
    if( error.code === USER_NOT_CONFIRMED_EXCEPTION ) {
      await dispatch( resendConfirmationCode( email ) );
      dispatch({
        type    : SIGN_IN_LOADING,
        payload : false
      });
    }
    else {
      dispatch({
        type    : SIGN_IN_ERROR,
        payload : getErrorMessage( error ),
        error
      });
    }
  }
};

export const signUpUser = ( email, password, allianceId, retry ) => async ( dispatch ) => {
  try {
    dispatch({
      type    : retry ? SIGN_UP_LOADING_RETRY : SIGN_UP_LOADING,
      payload : true
    });
    const authSession = await authService.signUp( email, password, allianceId );
    setUserToTrackProperties( authSession.user.username, true );

    dispatch({
      type    : retry ? SIGN_UP_SUCCESS_RETRY : SIGN_UP_SUCCESS,
      payload : true
    });
  }
  catch ( error ) {
    dispatch({
      type    : retry ? SIGN_UP_ERROR_RETRY : SIGN_UP_ERROR,
      payload : getErrorMessage( error ),
      error
    });
  }
};

export const confirmVerificationCode = ( email, authCode ) => async ( dispatch ) => {
  try {
    dispatch({
      type    : GET_VERIFICATION_CODE_LOADING,
      payload : true
    });
    await authService.confirmVerificationCode( email, authCode );

    dispatch({
      type    : GET_VERIFICATION_CODE_SUCCESS,
      payload : true
    });
  }
  catch ( error ) {
    dispatch({
      type    : GET_VERIFICATION_CODE_ERROR,
      payload : getErrorMessage( error ),
      error
    });
  }
};

export const sendRecoveryMail = ( email ) => async ( dispatch ) => {
  try {
    dispatch({
      type : SEND_RECOVERY_MAIL_LOADING
    });
    await authService.sendRecoveryMail( email );

    dispatch({
      type : SEND_RECOVERY_MAIL_SUCCESS
    });
  }
  catch ( error ) {
    dispatch({
      type    : SEND_RECOVERY_MAIL_ERROR,
      payload : getErrorMessage( error )
    });
  }
};

export const submitNewPassword = ( email, authCode, password ) => async ( dispatch ) => {
  try {
    dispatch({
      type : SUBMIT_NEW_PASSWORD_LOADING
    });
    await authService.submitNewPassword( email, authCode, password );

    dispatch({
      type : SUBMIT_NEW_PASSWORD_SUCCESS
    });
  }
  catch ( error ) {
    dispatch({
      type    : SUBMIT_NEW_PASSWORD_ERROR,
      payload : getErrorMessage( error )
    });
  }
};

export const restoreRecoverPassword = () => async ( dispatch ) => {
  dispatch({
    type : RESTORE_RECOVER_PASSWORD
  });
};

export const restoreSignIn = () => async ( dispatch ) => {
  dispatch({
    type : RESTORE_SIGN_IN
  });
};

export const restoreSignUp = () => async ( dispatch ) => {
  dispatch({
    type : RESTORE_SIGN_UP
  });
};

export const restoreConfirmationCodeVerified = () => async ( dispatch ) => {
  dispatch({
    type : RESTORE_CONFIRMATION_CODE_VERIFIED
  });
};

export const signOutUser = () => async ( dispatch ) => {
  try {
    dispatch({
      type : SIGN_OUT_LOADING
    });
    await authService.signOut();
    dispatch( clearRequestData() );
    dispatch({
      type : SIGN_OUT_SUCCESS
    });
  }
  catch ( error ) {
    dispatch({
      type    : SIGN_OUT_ERROR,
      payload : getErrorMessage( error )
    });
  }
};

export const reportUserError = ( error ) => async ( dispatch ) => (
  dispatch({
    type : COMPONENT_ERROR,
    error
  })
);
