import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  brands                 : undefined,
  areBrandsLoading       : false,
  brandsError            : '',
  isCreateVehicleLoading : false,
  createVehicleError     : '',
  createdVehicle         : undefined,
  models                 : undefined,
  isModelsLoading        : false,
  modelsError            : '',
  vehicleTypes           : undefined,
  areVehicleTypesLoading : false,
  vehicleTypesError      : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.VEHICLE_GET_BRANDS_LOADING:
      return {
        ...state,
        areBrandsLoading : true,
        brandsError      : ''
      };
    case actionTypes.VEHICLE_GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands             : action.payload,
        areBrandsLoading   : false,
        brandsError        : '',
        createVehicleError : ''
      };
    case actionTypes.VEHICLE_GET_BRANDS_ERROR:
      return {
        ...state,
        areBrandsLoading : false,
        brandsError      : action.payload
      };
    case actionTypes.CREATE_VEHICLE_LOADING:
      return {
        ...state,
        isCreateVehicleLoading : true,
        createVehicleError     : ''
      };
    case actionTypes.CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        isCreateVehicleLoading : false,
        createdVehicle         : action.payload,
        createVehicleError     : ''
      };
    case actionTypes.CREATE_VEHICLE_ERROR:
      return {
        ...state,
        isCreateVehicleLoading : false,
        createVehicleError     : action.payload
      };
    case actionTypes.VEHICLE_GET_MODELS_LOADING:

      return {
        ...state,
        isModelsLoading : true,
        modelsError     : ''
      };

    case actionTypes.VEHICLE_GET_MODELS_SUCCESS:
      return {
        ...state,
        models          : action.payload,
        isModelsLoading : false,
        modelsError     : ''
      };
    case actionTypes.VEHICLE_GET_MODELS_ERROR:
      return {
        ...state,
        isModelsLoading : false,
        modelsError     : action.payload
      };
    case actionTypes.VEHICLE_GET_TYPES_LOADING:

      return {
        ...state,
        areVehicleTypesLoading : true,
        vehicleTypesError      : ''
      };

    case actionTypes.VEHICLE_GET_TYPES_SUCCESS:
      return {
        ...state,
        vehicleTypes           : action.payload,
        areVehicleTypesLoading : false,
        vehicleTypesError      : ''
      };
    case actionTypes.VEHICLE_GET_TYPES_ERROR:
      return {
        ...state,
        areVehicleTypesLoading : false,
        vehicleTypesError      : action.payload
      };


    default:
      return state;
  }
};
