import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Accordion,
  AccordionSummary,
  Grid,
  Hidden,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandLess';

import {
  Logo,
  Plate,
  Splash,
  constants
} from '@alfred-co/alfred-react-components';
import { getTracking } from '../../redux/actions/tracking/trackingActions';
import useTrackingStyles from './trackingStyles';
import whiteChubb from '../../assets/icons/chubb.svg';
import TrackingTimeline from './components/TrackingTimeline';
import TrackingRight from './components/TrackingRight';
import TrackingNotFound from './components/TrackingNotFound';
import { homePath } from '../../utils/paths';

const { STATE_TITLE_MAP } = constants;

const Tracking = () => {
  const classes = useTrackingStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    tracking,
    isTrackingLoading,
    trackingError
  } = useSelector( ( state ) => state.trackingReducer );
  const [ expand, setExpand ] = useState( false );

  useEffect( () => {
    if( !uuid ) return;
    dispatch( getTracking( uuid ) );
  }, [ dispatch, uuid ] );

  const toggleAcordion = useCallback( () => {
    setExpand( ( prev ) => !prev );
  }, [] );

  const handleLogoClick = useCallback( () => {
    navigate( homePath );
  }, [ navigate ] );

  if( trackingError ) return <TrackingNotFound />;

  if( isTrackingLoading || !tracking ) return <Splash />;

  return (
    <Grid container justifyContent="center" alignContent="flex-start" alignItems="stretch" className={ classes.trackingContainer }>
      <Grid container item justifyContent="flex-end" className={ classes.trackingLeftContainer } xs={ 12 } md={ 6 }>
        <Grid container direction="column" item className={ classes.trackingLeftInfo }>

          <Grid container item direction="row" alignItems="center">
            <Logo
              logoStyles={ classes.trackingHeaderLogo }
              onClick={ handleLogoClick }
            />
            <Hidden smDown>
              <div className={ classes.headerChubbSection }>
                <span className={ classes.headerChubbText }>Asegurado por: </span>
                <img
                  src={ whiteChubb }
                  alt="CHUBB logo"
                  className={ classes.headerChubbImg }
                />
              </div>
            </Hidden>
          </Grid>

          <Hidden smDown>
            <Grid container justifyContent="space-around" alignItems="center" className={ classes.trackingState }>
              <Typography variant="subtitle1">{ STATE_TITLE_MAP[ tracking.state ] }</Typography>
              <Plate
                plate={ tracking?.vehicle?.plate }
                versionMedium
                disableHover
              />
            </Grid>
            <Grid container className={ classes.trackingTimeLine }>
              <TrackingTimeline />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container justifyContent="space-around" alignItems="center" className={ classes.trackingState }>
              <Typography variant="subtitle1">{ STATE_TITLE_MAP[ tracking.state ] }</Typography>
              <Plate
                plate={ tracking?.vehicle?.plate }
                versionMini
                disableHover
              />
            </Grid>
            <TrackingRight />
          </Hidden>

        </Grid>
      </Grid>

      <Grid container item className={ classes.trackingRightInfo } xs={ 12 } md={ 6 }>
        <Hidden smDown>
          <Grid container className={ classes.trackingTimeLine }>
            <TrackingRight />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Accordion expanded={ expand } className={ classes.trackingAcordion }>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              IconButtonProps={ {
                onClick : toggleAcordion
              } }
            >
              <Typography variant="body1">Detalle del servicio</Typography>
            </AccordionSummary>
            <TrackingTimeline />
          </Accordion>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default Tracking;
