import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import theme from './commons/styles/theme';
import { initBugReporter } from './utils/bugReportUtils';
import { initUserTracker } from './utils/userTrackerUtils';
import store from './redux/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

initBugReporter();
initUserTracker();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById( 'root' )
);

serviceWorker.unregister();
require( 'dotenv' ).config();
