import { makeStyles } from '@material-ui/core';


const useTrackingImageStyles = makeStyles(
  ( theme ) => ({
    trackingImageContainer : {
      width : '70px'
    },
    trackingSVGContainer : {
      width           : '70px',
      height          : '66px',
      borderRadius    : '4px',
      backgroundColor : theme.palette.primary.contrastText,
      display         : 'flex',
      justifyContent  : 'center',
      alignContent    : 'flex-end',

      '& svg' : {
        height : '60px'
      }
    },
    trackingImage : {
      backgroundColor                  : theme.palette.primary.white,
      borderRadius                     : '4px',
      width                            : '56px',
      height                           : '66px',
      objectFit                        : 'cover',
      objectPosition                   : '0 20%',
      [ theme.breakpoints.up( 'md' ) ] : {
        width : '70px'
      }
    }
  })
);

export default useTrackingImageStyles;
