import { SIGN_OUT_SUCCESS, SIGN_IN_ERROR } from '../actions/types';
import { REFRESH_TOKEN_EXPIRED } from '../errorCodes';

export default ( store ) => ( next ) => ( action ) => {
  const result = next( action );
  if(
    action.type !== SIGN_IN_ERROR
     && action.error
     && action.error.code === REFRESH_TOKEN_EXPIRED
  ) {
    store.dispatch({ type: SIGN_OUT_SUCCESS });
  }
  return result;
};
