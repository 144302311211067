import { makeStyles } from '@material-ui/core/styles';


const useNotFoundStyles = makeStyles( ( theme ) => ({
  trackingNotFound : {
    padding         : '0',
    margin          : '0',
    overflowX       : 'hidden',
    overflowY       : 'hidden',
    fontSize        : '1rem',
    minHeight       : '100vh',
    backgroundColor : theme.palette.primary.dark
  },
  trackingNotFoundLogo : {
    marginBottom : theme.margin( 2 )
  },
  trackingNotFoundSpinner : {
    color : theme.palette.primary.main
  },
  trackingNotFoundFloating : {
    position : 'fixed',
    right    : '15px',
    bottom   : '20px',
    margin   : '0px'
  }
}) );

export default useNotFoundStyles;
