import { constants } from '@alfred-co/alfred-react-components';
import CouponService from '../../services/CouponService';
import PaymentMethodService from '../../services/PaymentMethodService';
import ServiceService from '../../services/ServiceService';
import {
  PAYMENT_ADD_PAYMENTS_RESUME,
  PAYMENT_ERROR,
  PAYMENT_GET_SERVICES_ERROR,
  PAYMENT_GET_SERVICES_LOADING,
  PAYMENT_GET_SERVICES_SUCCESS,
  PAYMENT_STATUS,
  PAYMENT_GET_SERVICE_PRICE_LOADING,
  PAYMENT_GET_SERVICE_PRICE_SUCCESS,
  PAYMENT_GET_SERVICE_PRICE_ERROR,
  CLEAR_SERVICE_PRICE,
  CLEAR_TOTALS
} from '../types';
import { getTotals } from '../user/couponActions';

const { TRANSPORT_LABORS_SLUG } = constants;

export const paymentResumen = ( originCase, items, type, service ) => async ( dispatch ) => {
  const withoutTransport = items
    .filter( ( labor ) => labor.id !== TRANSPORT_LABORS_SLUG[ 0 ] );

  dispatch({
    type    : PAYMENT_ADD_PAYMENTS_RESUME,
    payload : {
      originCase,
      items   : withoutTransport,
      service : {
        type,
        value               : service?.uuid,
        paymentAttemptState : service?.paymentAttemptState,
        id                  : service?.id
      }
    }

  });
};

export const paymentStatus = () => async ( dispatch, getState ) => {
  try {
    const { origin } = getState().paymentReducer;
    const { service } = getState().userReducer;

    const status = await PaymentMethodService.paymentStatus( origin?.value ?? service.uuid );

    dispatch({
      type    : PAYMENT_STATUS,
      payload : status
    });
  }
  catch ( error ) {
    dispatch({
      type    : PAYMENT_ERROR,
      payload : error.message
    });
  }
};

export const getServicesWithCoupon = ( uuid ) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type : PAYMENT_GET_SERVICES_LOADING
    });

    if( uuid ) {
      const { user } = getState().userReducer;

      const services = await CouponService.getServicesWithCoupon( user, uuid );

      dispatch({
        type    : PAYMENT_GET_SERVICES_SUCCESS,
        payload : {
          ...services,
          paymentItems : services.serviceLabors
        }
      });
    }
    else {
      const { servicePrice, paymentItems } = getState().paymentReducer;

      const services = getTotals( servicePrice );

      dispatch({
        type    : PAYMENT_GET_SERVICES_SUCCESS,
        payload : {
          ...services,
          paymentItems
        }
      });
    }
  }
  catch ( error ) {
    dispatch({
      type    : PAYMENT_GET_SERVICES_ERROR,
      payload : error.message
    });
  }
};


export const getServicePrice = ( serviceLabors ) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type : PAYMENT_GET_SERVICE_PRICE_LOADING
    });

    const { serviceToRequest } = getState().requestReducer;

    const withoutTransport = serviceLabors
      .filter( ( labor ) => labor.labor.id !== TRANSPORT_LABORS_SLUG[ 0 ] );

    const { vehicleType } = serviceToRequest.vehicle;

    const servicePrice = await ServiceService.getServicePrice( withoutTransport, vehicleType );

    dispatch({
      type    : PAYMENT_GET_SERVICE_PRICE_SUCCESS,
      payload : servicePrice
    });
  }
  catch ( error ) {
    dispatch({
      type    : PAYMENT_GET_SERVICE_PRICE_ERROR,
      payload : error.message
    });
  }
};

export const clearServicePrice = ( ) => async ( dispatch ) => (
  dispatch({
    type : CLEAR_SERVICE_PRICE
  })
);

export const clearTotals = ( ) => async ( dispatch ) => (
  dispatch({
    type : CLEAR_TOTALS
  })
);

export const verifyServicePrice = ( serviceLabors ) => async ( dispatch ) => {
  if( serviceLabors?.length ) {
    return dispatch( getServicePrice( serviceLabors ) );
  }
  return dispatch( clearTotals() );
};
