import React, {
  useCallback,
  useEffect
} from 'react';
import {
  Route,
  Routes
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Amplify from 'aws-amplify';
import { ErrorPage } from '@alfred-co/alfred-react-components';
import * as Sentry from '@sentry/react';

import { reportUserError } from './redux/actions/user/authActions';

import {
  homePath,
  page404Path,
  trackServicePath,
  getAppPath
} from './utils/paths';
import awsconfig from './config/amplifyConfig';
import Tracking from './modules/tracking/Tracking';
import GetApp from './modules/getApp/GetApp';


const errorPageImage = `${ process.env.REACT_APP_AWS_S3_ASSETS_BUCKET }/404_image.jpg`;

Amplify.configure( awsconfig );

function App() {
  const dispatch = useDispatch();
  const handleClick404 = useCallback( () => {
    window.location = homePath;
  }, [ ] );

  const myFallbackErrorPage = () => (
    <ErrorPage
      errorTitle="Error"
      errorMessage="Lo sentimos, ha ocurrido un error inesperado."
      onClickErrorPage={ handleClick404 }
      image={ errorPageImage }
    />
  );

  const handleComponentError = useCallback( ( error ) => {
    dispatch( reportUserError( error ) );
  }, [ dispatch ] );

  return (
    <Sentry.ErrorBoundary
      fallback={ myFallbackErrorPage }
      onError={ handleComponentError }
    >
      <Routes>
        <Route
          path={ trackServicePath }
          element={ (
            <Tracking />
          ) }
        />
        <Route
          path={ getAppPath }
          element={ (
            <GetApp />
          ) }
        />
        <Route
          path={ page404Path }
          element={ ( <Redirect redirectUrl="https://alfred.co" /> ) }
        />
      </Routes>
    </Sentry.ErrorBoundary>
  );
}
const Redirect = ( props ) => {
  const { redirectUrl } = props;
  useEffect( () => {
    window.location.href = redirectUrl;
  }, [ redirectUrl ] );

  return <></>;
};


export default App;
