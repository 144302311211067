import visaIcon from '../assets/icons/visa.svg';
import mastercardIcon from '../assets/icons/mastercard.svg';
import amexIcon from '../assets/icons/amex.svg';


export function sortPaymentMethodsBySelected( paymentMethods ) {
  return paymentMethods.sort( ( paymentMethod1, paymentMethod2 ) => (
    // eslint-disable-next-line no-nested-ternary
    paymentMethod1.selected === paymentMethod2.selected
    ? 0
    : paymentMethod1.selected
      ? -1
      : 1
  ) );
}

export function getCardIcon( paymentMethod ) {
  let selectedIcon;
  switch ( paymentMethod.brand ) {
    case 'VISA':
      selectedIcon = visaIcon;
      break;
    case 'MASTERCARD':
      selectedIcon = mastercardIcon;
      break;
    case 'AMEX':
      selectedIcon = amexIcon;
      break;
    default:
      break;
  }
  return selectedIcon;
}

export function getPaymentMethodsList( elements ) {
  return elements?.reduce( ( method, paymentMethod ) => {
    const {
      key,
      object,
      icon,
      cardNumber
    } = paymentMethod;
    method.push({
      key,
      object,
      icon,
      name : '****'.concat( cardNumber )
    });
    return method;
  }, [] );
}
