export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_LOADING = 'GET_SESSION_LOADING';
export const GET_SESSION_ERROR = 'GET_SESSION_ERROR';

export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const RESTORE_UPDATE_USER = 'RESTORE_UPDATE_USER';

export const GET_VEHICLES_LOADING = 'GET_VEHICLES_LOADING';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
export const GET_VEHICLES_ERROR = 'GET_VEHICLES_ERROR';

export const GET_RELATED_VEHICLES_LOADING = 'GET_RELATED_VEHICLES_LOADING';
export const GET_RELATED_VEHICLES_SUCCESS = 'GET_RELATED_VEHICLES_SUCCESS';
export const GET_RELATED_VEHICLES_ERROR = 'GET_RELATED_VEHICLES_ERROR';
export const ADD_RELATED_VEHICLE = 'ADD_RELATED_VEHICLE';

export const RESTORE_VEHICLE_DETAIL = 'RESTORE_VEHICLE_DETAIL';

export const GET_VEHICLE_LOADING = 'GET_VEHICLE_LOADING';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_ERROR = 'GET_VEHICLE_ERROR';

export const DELETE_VEHICLE_LOADING = '  DELETE_VEHICLE_LOADING';
export const DELETE_VEHICLE_SUCCESS = '  DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_ERROR = 'DELETE_VEHICLE_ERROR';

export const CREATE_VEHICLE_LOADING = 'CREATE_VEHICLE_LOADING';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_ERROR = 'CREATE_VEHICLE_ERROR';

export const VEHICLE_GET_MODELS_LOADING = 'VEHICLE_GET_MODELS_LOADING';
export const VEHICLE_GET_MODELS_SUCCESS = 'VEHICLE_GET_MODELS_SUCCESS';
export const VEHICLE_GET_MODELS_ERROR = 'VEHICLE_GET_MODELS_ERROR';

export const GET_ADDRESSES_LOADING = 'GET_ADDRESSES_LOADING';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_ERROR = 'GET_ADDRESSES_ERROR';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_ADDRESS_LOADING = 'ADD_ADDRESS_LOADING';
export const ADD_ADDRESS_ERROR = 'ADD_ADDRESS_ERROR';
export const DELETE_ADDRESS_LOADING = 'DELETE_ADDRESS_LOADING';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_ERROR = 'DELETE_ADDRESS_ERROR';


export const GET_LABORS_LOADING = 'GET_LABORS_LOADING';
export const GET_LABORS_SUCCESS = 'GET_LABORS_SUCCESS';
export const GET_LABORS_ERROR = 'GET_LABORS_ERROR';

export const GET_CURRENT_SERVICES_LOADING = 'GET_CURRENT_SERVICES_LOADING';
export const GET_CURRENT_SERVICES_SUCCESS = 'GET_CURRENT_SERVICES_SUCCESS';
export const GET_CURRENT_SERVICES_ERROR = 'GET_CURRENT_SERVICES_ERROR';
export const ADD_CURRENT_SERVICE = 'ADD_CURRENT_SERVICE';
export const UPDATE_CURRENT_SERVICE = 'UPDATE_CURRENT_SERVICE';
export const REMOVE_CURRENT_SERVICE = 'REMOVE_CURRENT_SERVICE';

export const GET_CITIES_LOADING = 'GET_CITIES_LOADING';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';

export const GET_SERVICES_HISTORY_LOADING = 'GET_SERVICES_HISTORY_LOADING';
export const GET_SERVICES_HISTORY_SUCCESS = 'GET_SERVICES_HISTORY_SUCCESS';
export const GET_SERVICES_HISTORY_ERROR = 'GET_SERVICES_HISTORY_ERROR';

export const GET_MERCHANT_LOADING = 'GET_MERCHANT_LOADING';
export const GET_MERCHANT_SUCCESS = 'GET_MERCHANT_SUCCESS';
export const GET_MERCHANT_ERROR = 'GET_MERCHANT_ERROR';

export const CREATE_MEMBERSHIP_LOADING = 'CREATE_MEMBERSHIP_LOADING';
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBERSHIP_SUCCESS';
export const CREATE_MEMBERSHIP_ERROR = 'CREATE_MEMBERSHIP_ERROR';

export const CREATE_SERVICE_LOADING = 'CREATE_SERVICE_LOADING';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_ERROR = 'CREATE_SERVICE_ERROR';
export const CLEAN_SERVICE_ERROR = 'CLEAN_SERVICE_ERROR';

export const GET_MEMBERSHIP_INVOICES_LOADING = 'GET_MEMBERSHIP_INVOICES_LOADING';
export const GET_MEMBERSHIP_INVOICES_SUCCESS = 'GET_MEMBERSHIP_INVOICES_SUCCESS';
export const GET_MEMBERSHIP_INVOICES_ERROR = 'GET_MEMBERSHIP_INVOICES_ERROR';

export const GET_SELECTED_PAYMENT_METHOD_LOADING = 'GET_SELECTED_PAYMENT_METHOD_LOADING';
export const GET_SELECTED_PAYMENT_METHOD_SUCCESS = 'GET_SELECTED_PAYMENT_METHOD_SUCCESS';
export const GET_SELECTED_PAYMENT_METHOD_ERROR = 'GET_SELECTED_PAYMENT_METHOD_ERROR';

export const ADD_PAYMENT_METHOD_LOADING = 'ADD_PAYMENT_METHOD_LOADING';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_ERROR = 'ADD_PAYMENT_METHOD_ERROR';

export const SELECT_PAYMENT_METHOD_LOADING = 'SELECT_PAYMENT_METHOD_LOADING';
export const SELECT_PAYMENT_METHOD_SUCCESS = 'SELECT_PAYMENT_METHOD_SUCCESS';
export const SELECT_PAYMENT_METHOD_ERROR = 'SELECT_PAYMENT_METHOD_ERROR';

export const GET_PAYMENT_METHODS_LOADING = 'GET_PAYMENT_METHODS_LOADING';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR';

export const DELETE_PAYMENT_METHOD_LOADING = 'DELETE_PAYMENT_METHOD_LOADING';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_ERROR = 'DELETE_PAYMENT_METHOD_ERROR';

export const GET_AUTHENTICATED_USER_LOADING = 'GET_AUTHENTICATED_USER_LOADING';
export const GET_AUTHENTICATED_USER_SUCCESS = 'GET_AUTHENTICATED_USER_SUCCESS';
export const GET_AUTHENTICATED_USER_ERROR = 'GET_AUTHENTICATED_USER_ERROR';

export const CREATE_COUPON_LOADING = 'CREATE_COUPON_LOADING';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';

export const GET_LABOR_DETAIL_LOADING = 'GET_LABOR_DETAIL_LOADING';
export const GET_LABOR_DETAIL_SUCCESS = 'GET_LABOR_DETAIL_SUCCESS';
export const GET_LABOR_DETAIL_ERROR = 'GET_LABOR_DETAIL_ERROR';

export const GET_DAYSOFF_LOADING = 'GET_DAYSOFF_LOADING';
export const GET_DAYSOFF_SUCCESS = 'GET_DAYSOFF_SUCCESS';
export const GET_DAYSOFF_ERROR = 'GET_DAYSOFF_ERROR';

export const GET_BLOCKED_SLOTS_LOADING = 'GET_BLOCKED_SLOTS_LOADING';
export const GET_BLOCKED_SLOTS_SUCCESS = 'GET_BLOCKED_SLOTS_SUCCESS';
export const GET_BLOCKED_SLOTS_ERROR = 'GET_BLOCKED_SLOTS_ERROR';

export const CREATE_SERVICE_INVOICE_LOADING = 'CREATE_SERVICE_INVOICE_LOADING';
export const CREATE_SERVICE_INVOICE_SUCCESS = 'CREATE_SERVICE_INVOICE_SUCCESS';
export const CREATE_SERVICE_INVOICE_ERROR = 'CREATE_SERVICE_INVOICE_ERROR';

export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_UP_LOADING = 'SIGN_UP_LOADING';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_UP_LOADING_RETRY = 'SIGN_UP_LOADING_RETRY';
export const SIGN_UP_SUCCESS_RETRY = 'SIGN_UP_SUCCESS_RETRY';
export const SIGN_UP_ERROR_RETRY = 'SIGN_UP_ERROR_RETRY';

export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const SIGN_IN_RESEND_CODE_LOADING = 'SIGN_IN_RESEND_CODE_LOADING';
export const SIGN_IN_RESEND_CODE_SUCCESS = 'SIGN_IN_RESEND_CODE_SUCCESS';
export const SIGN_IN_RESEND_CODE_ERROR = 'SIGN_IN_RESEND_CODE_ERROR';

export const GET_VERIFICATION_CODE_LOADING = 'GET_VERIFICATION_CODE_LOADING';
export const GET_VERIFICATION_CODE_SUCCESS = 'GET_VERIFICATION_CODE_SUCCESS';
export const GET_VERIFICATION_CODE_ERROR = 'GET_VERIFICATION_CODE_ERROR';

export const SEND_RECOVERY_MAIL_LOADING = 'SEND_RECOVERY_MAIL_LOADING';
export const SEND_RECOVERY_MAIL_SUCCESS = 'SEND_RECOVERY_MAIL_SUCCESS';
export const SEND_RECOVERY_MAIL_ERROR = 'SEND_RECOVERY_MAIL_ERROR';

export const SUBMIT_NEW_PASSWORD_LOADING = 'SUBMIT_NEW_PASSWORD_LOADING';
export const SUBMIT_NEW_PASSWORD_SUCCESS = 'SUBMIT_NEW_PASSWORD_SUCCESS';
export const SUBMIT_NEW_PASSWORD_ERROR = 'SUBMIT_NEW_PASSWORD_ERROR';

export const RESTORE_RECOVER_PASSWORD = 'RESTORE_RECOVER_PASSWORD';
export const RESTORE_SIGN_IN = 'RESTORE_SIGN_IN';
export const RESTORE_SIGN_UP = 'RESTORE_SIGN_UP';
export const RESTORE_CONFIRMATION_CODE_VERIFIED = 'RESTORE_CONFIRMATION_CODE_VERIFIED';

export const RESTORE_SERVICE_INVOICE = 'RESTORE_SERVICE_INVOICE';
export const RESTORE_SERVICE_DETAIL = 'RESTORE_SERVICE_DETAIL';

export const PAYMENT_ADD_PAYMENTS_RESUME = 'PAYMENT_ADD_PAYMENTS_RESUME';
export const PAYMENT_SHOW_COUPON = 'PAYMENT_SHOW_COUPON';
export const PAYMENT_GENERATE_UUID = 'PAYMENT_GENERATE_UUID';
export const PAYMENT_UUID_LOADING = 'PAYMENT_UUID_LOADING';
export const PAYMENT_UUID_SUCCESS = 'PAYMENT_UUID_SUCCESS';
export const PAYMENT_UUID_CLEAR = 'PAYMENT_UUID_CLEAR';
export const PAYMENT_ORIGIN = 'PAYMENT_ORIGIN';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const COUPON_SERVICE_LOADING = 'COUPON_SERVICE_LOADING';
export const COUPON_SERVICE_SUCCESS = 'COUPON_SERVICE_SUCCESS';
export const COUPON_SERVICE_ERROR = 'COUPON_SERVICE_ERROR';
export const CLEAR_COUPON_SERVICE = 'CLEAR_COUPON_SERVICE';
export const ADD_COUPON_SERVICE_SUCCESS = 'ADD_COUPON_SERVICE_SUCCESS';
export const PAYMENT_GET_SERVICES_SUCCESS = 'PAYMENT_GET_SERVICES_SUCCESS';
export const PAYMENT_GET_SERVICES_ERROR = 'PAYMENT_GET_SERVICES_ERROR';
export const PAYMENT_GET_SERVICES_LOADING = 'PAYMENT_GET_SERVICES_LOADING';
export const DELETE_COUPON_SERVICE_SUCCESS = 'DELETE_COUPON_SERVICE_SUCCESS';
export const DELETE_COUPON_SERVICE_ERROR = 'DELETE_COUPON_SERVICE_ERROR';
export const DELETE_COUPON_SERVICE_LOADING = 'DELETE_COUPON_SERVICE_LOADING';
export const PAYMENT_GET_SERVICE_PRICE_LOADING = 'PAYMENT_GET_SERVICE_PRICE_LOADING';
export const PAYMENT_GET_SERVICE_PRICE_SUCCESS = 'PAYMENT_GET_SERVICE_PRICE_SUCCESS';
export const PAYMENT_GET_SERVICE_PRICE_ERROR = 'PAYMENT_GET_SERVICE_PRICE_ERROR';
export const CLEAR_SERVICE_PRICE = 'CLEAR_SERVICE_PRICE';
export const CLEAR_TOTALS = 'CLEAR_TOTALS';

export const COMPONENT_ERROR = 'COMPONENT_ERROR';

export const REQUEST_SERVICE_STEP = 'REQUEST_SERVICE_STEP';
export const REQUEST_SERVICE_UPDATE = 'REQUEST_SERVICE_UPDATE';
export const REQUEST_SERVICE_UPDATE_LOADING = 'REQUEST_SERVICE_UPDATE_LOADING';
export const REQUEST_SERVICE_CLEAR = 'REQUEST_SERVICE_CLEAR';
export const REQUEST_SERVICE_SERVICE = 'REQUEST_SERVICE_SERVICE';
export const REQUEST_SERVICE_VALIDATE = 'REQUEST_SERVICE_VALIDATE';
export const REQUEST_SERVICE_CATEGORY = 'REQUEST_SERVICE_CATEGORY';
export const REQUEST_SERVICE_LABOR = 'REQUEST_SERVICE_LABOR';
export const REQUEST_SERVICE_SHOP = 'REQUEST_SERVICE_SHOP';

export const GET_CATEGORIES_LOADING = 'GET_CATEGORIES_LOADING';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_CATEGORY_LOADING = 'GET_CATEGORY_LOADING';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';

export const GET_LABORS_WITHOUT_CATEGORY_LOADING = 'GET_LABORS_WITHOUT_CATEGORY_LOADING';
export const GET_LABORS_WITHOUT_CATEGORY_SUCCESS = 'GET_LABORS_WITHOUT_CATEGORY_SUCCESS';
export const GET_LABORS_WITHOUT_CATEGORY_ERROR = 'GET_LABORS_WITHOUT_CATEGORY_ERROR';

export const TRACKING_SERVICE_LOADING = 'TRACKING_SERVICE_LOADING';
export const TRACKING_SERVICE_SUCCESS = 'TRACKING_SERVICE_SUCCESS';
export const TRACKING_SERVICE_ERROR = 'TRACKING_SERVICE_ERROR';
export const TRACKING_SERVICE_CLEAR = 'TRACKING_SERVICE_CLEAR';
export const TRACKING_SET_MAP = 'TRACKING_SET_MAP';

export const GET_SHOPS_LOADING = 'GET_SHOPS_LOADING';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_ERROR = 'GET_SHOPS_ERROR';
export const GET_SHOP_AGREEMENT_LOADING = 'GET_SHOP_AGREEMENT_LOADING';
export const GET_SHOP_AGREEMENT_SUCCESS = 'GET_SHOP_AGREEMENT_SUCCESS';
export const GET_SHOP_AGREEMENT_ERROR = 'GET_SHOP_AGREEMENT_ERROR';
export const CLEAR_SHOP_AGREEMENT = 'CLEAR_SHOP_AGREEMENT';

export const VEHICLE_GET_BRANDS_LOADING = 'GET_BRANDS_LOADING';
export const VEHICLE_GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const VEHICLE_GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';

export const GET_ALFRED_SCHEDULE_LOADING = 'GET_ALFRED_SCHEDULE_LOADING';
export const GET_ALFRED_SCHEDULE_SUCCESS = 'GET_ALFRED_SCHEDULE_SUCCESS';
export const GET_ALFRED_SCHEDULE_ERROR = 'GET_ALFRED_SCHEDULE_ERROR';

export const VEHICLE_GET_TYPES_LOADING = 'GET_TYPES_LOADING';
export const VEHICLE_GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS';
export const VEHICLE_GET_TYPES_ERROR = 'GET_TYPES_ERROR';
