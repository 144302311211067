import { Auth } from 'aws-amplify';
import { constants } from '@alfred-co/alfred-react-components';

import userService from './UserService';

const { DATA_PROCESSING_AUTHORIZATION_URL } = constants;
const termsAndConditionsUrl = process.env.REACT_APP_TERMS_AND_CONDITIONS_URL;


async function getUser() {
  const fetchedUser = await userService.getUser();
  return { ...fetchedUser };
}

async function resendConfirmationCode( email ) {
  return Auth.resendSignUp( email );
}

async function signIn( email, password ) {
  return Auth.signIn({ username: email, password });
}

async function getSession() {
  return Auth.currentAuthenticatedUser();
}

async function signUp( email, password, allianceId ) {
  const attributes = {
    'custom:terms_conditions' : termsAndConditionsUrl,
    'custom:data_treatment'   : DATA_PROCESSING_AUTHORIZATION_URL
  };

  // If this function is called with an allianceId it should
  // be persisted in Cognito.
  if( allianceId ) {
    attributes[ 'custom:alliance_id' ] = allianceId;
  }
  // eslint-disable-next-line no-undef
  const recaptchaToken = await grecaptcha.execute( process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'signUp' });

  return Auth.signUp({
    password,
    username       : email,
    attributes,
    validationData : {
      recaptchaToken
    }
  });
}

async function confirmVerificationCode( email, authCode ) {
  return Auth.confirmSignUp( email, authCode );
}

async function sendRecoveryMail( email ) {
  return Auth.forgotPassword( email );
}

async function submitNewPassword(
  email,
  authCode,
  password
) {
  return Auth.forgotPasswordSubmit( email, authCode, password );
}

async function signOut( ) {
  return Auth.signOut();
}

export default {
  getUser,
  resendConfirmationCode,
  signIn,
  getSession,
  signUp,
  confirmVerificationCode,
  sendRecoveryMail,
  submitNewPassword,
  signOut
};
