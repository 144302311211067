import mixpanel from 'mixpanel-browser';

import packageJson from '../../package.json';
import { toShortTimestamp } from './dateUtils';


export function initUserTracker() {
  if( process.env.REACT_APP_DEBUG_MODE === 'false' ) {
    mixpanel.init( process.env.REACT_APP_MIXPANEL_TOKEN );
  }
}

export function setUserToTrackProperties( email, isNewUser ) {
  // only reserved properties need the $
  // reserved props: https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties#reserved-properties-for-user-profiles
  if( process.env.REACT_APP_DEBUG_MODE === 'false' ) {
    const userProperties = {
      $email  : email,
      USER_ID : email
    };

    if( isNewUser ) {
      userProperties[ 'Registration Date' ] = toShortTimestamp( Date.now() );
    }

    mixpanel.people.set( userProperties );
  }
}

export function identifyUserToTrack( email ) {
  if( process.env.REACT_APP_DEBUG_MODE === 'false' ) {
    mixpanel.identify( email );
    setUserToTrackProperties( email );
  }
}

export function trackEvent( event, properties = {}) {
  if( process.env.REACT_APP_DEBUG_MODE === 'false' ) {
    mixpanel.track(
      event,
      { ...properties, version: packageJson.version }
    );
  }
}
