import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  tracking          : undefined,
  isTrackingLoading : false,
  trackingError     : '',
  map               : undefined,
  lastEvent         : undefined,
  showMap           : false,
  activeLabor       : undefined
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.TRACKING_SERVICE_LOADING:
      return {
        ...state,
        isTrackingLoading : true,
        trackingError     : ''
      };
    case actionTypes.TRACKING_SERVICE_SUCCESS:
      return {
        ...state,
        tracking          : action.payload.trackingData,
        lastEvent         : action.payload.lastEvent,
        activeLabor       : action.payload.activeLabor,
        isTrackingLoading : false,
        trackingError     : ''
      };
    case actionTypes.TRACKING_SERVICE_ERROR:
      return {
        ...state,
        isTrackingLoading : false,
        trackingError     : action.payload
      };
    case actionTypes.TRACKING_SET_MAP:
      return {
        ...state,
        map     : action.payload.map,
        showMap : action.payload.showMap
      };
    default:
      return state;
  }
};
