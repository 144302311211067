import { trackEvent } from '../utils/userTrackerUtils';
import {
  INTERFACE_ADD_TO_CART,
  INTERFACE_CART
} from './gtmProptypes';

const gtmIsActive = () => process.env.REACT_APP_GA_TRACKING_ID;
const mixpanelIsActive = () => process.env.REACT_APP_MIXPANEL_TOKEN;

const getUserUuid = () => localStorage.getItem( 'userUuid' );

const vpv = ( data ) => {
  const {
    eventParams,
    userProperties,
    customerType
  } = data;
  const event = {
    event           : 'vpv',
    event_params    : eventParams,
    user_properties : userProperties,
    customer_type   : customerType
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addToCart = ( items ) => {
  const event = {
    event        : 'add_to_cart',
    event_params : {
      items,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};
addToCart.propTypes = INTERFACE_ADD_TO_CART;

const deleteService = ( items ) => {
  const event = {
    event        : 'delete_service',
    event_params : {
      items,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

deleteService.propTypes = INTERFACE_CART;

const addPaymentInfo = ( paymentType = 'Tarjeta de crédito', items = [] ) => {
  const event = {
    event     : 'add_payment_info',
    ecommerce : {
      payment_type : paymentType,
      items,
      user_uuid    : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};
addPaymentInfo.propTypes = INTERFACE_CART;

const signUp = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'sign_up',
    event_params : {
      ...eventParams
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const login = ( data ) => {
  const {
    eventParams,
    user,
    customerType
  } = data;
  const event = {
    event        : 'login',
    event_params : {
      ...eventParams,
      action : 'Clic en Iniciar sesión'
    },
    user_properties : {
      ...user
    },
    customer_type : customerType
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const logout = ( data ) => {
  const {
    eventParams,
    user,
    customerType
  } = data;
  const event = {
    event        : 'logout',
    event_params : {
      ...eventParams,
      action : 'Clic en cerrar sesión'
    },
    user_properties : {
      ...user
    },
    customer_type : customerType
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const search = ( data ) => {
  const {
    eventParams,
    user,
    customerType
  } = data;
  const event = {
    event           : 'search',
    event_params    : eventParams,
    user_properties : {
      ...user
    },
    customer_type : customerType
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToSignup = ( ) => {
  const event = {
    event : 'go_to_signup'
  };
  if( mixpanelIsActive() ) trackEvent( event.event );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToLogin = ( ) => {
  const event = {
    event : 'go_to_login'
  };
  if( mixpanelIsActive() ) trackEvent( event.event );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToRecoverAccount = ( ) => {
  const event = {
    event : 'go_to_forgot_password'
  };
  if( mixpanelIsActive() ) trackEvent( event.event );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const checkTermsAndConditionsBox = ( ) => {
  const event = {
    event : 'check_terms_and_conditions_box'
  };
  if( mixpanelIsActive() ) trackEvent( event.event );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToRequestService = ( data ) => {
  const event = {
    event        : 'go_to_request_service',
    event_params : {
      user_uuid : getUserUuid(),
      ...data?.event_params
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToCurrentService = ( ) => {
  const event = {
    event        : 'go_to_current_service',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToViewAddresses = ( ) => {
  if( window.dataLayer?.at( -1 ).event === 'go_to_view_addresses' ) return;
  const event = {
    event        : 'go_to_view_addresses',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToCart = ( ) => {
  const event = {
    event        : 'go_to_cart',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToVehicle = ( ) => {
  const event = {
    event        : 'go_to_vehicle',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const deleteVehicle = ( ) => {
  const event = {
    event        : 'delete_vehicle',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToPreviousService = ( ) => {
  const event = {
    event        : 'go_to_previous_service',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const pay = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'pay',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const changeSelectedVehicle = ( ) => {
  const event = {
    event        : 'change_selected_vehicle',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToAddVehicle = ( data ) => {
  if( window.dataLayer?.at( -1 ).event === 'go_to_add_vehicle' ) return;
  const {
    eventParams
  } = data;
  const event = {
    event        : 'go_to_add_vehicle',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToCategory = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'go_to_category',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const selectService = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'select_service',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const orderBy = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'order_by',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const selectAlly = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'select_ally',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const removeOption = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'remove_option',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addOption = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'add_option',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addComments = ( ) => {
  const event = {
    event        : 'add_comments',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const checkDeliverAnotherAddress = ( ) => {
  const event = {
    event        : 'check_deliver_another_address',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToChangeDeliveryAddress = ( ) => {
  const event = {
    event        : 'go_to_change_delivery_address',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToAddCoupon = ( ) => {
  const event = {
    event        : 'go_to_add_coupon',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addCoupon = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'add_coupon',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToSummary = ( ) => {
  const event = {
    event        : 'go_to_summary',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const selectPaymentMethod = () => {
  const event = {
    event        : 'select_payment_method',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const continueAddVehicle = ( ) => {
  const event = {
    event        : 'continue_add_vehicle',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addVehicle = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'add_vehicle',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };

  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const viewVehicleServices = ( ) => {
  const event = {
    event        : 'view_vehicle_services',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const viewVehicleInfo = ( ) => {
  const event = {
    event        : 'view_vehicle_info',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToAddAddress = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'go_to_add_address',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const continueAddAddress = ( ) => {
  const event = {
    event        : 'continue_add_address',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addAddress = ( ) => {
  const event = {
    event        : 'add_address',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const addPersonalInformation = ( ) => {
  const event = {
    event        : 'add_personal_information',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToSetDateTime = ( ) => {
  const event = {
    event        : 'go_to_set_datetime',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const changeDayDate = ( ) => {
  const event = {
    event        : 'change_day_date',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};
const selectTimeSlot = ( ) => {
  const event = {
    event        : 'select_time_slot',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};
const confirmTimeSlot = ( data ) => {
  const {
    eventParams
  } = data;
  const event = {
    event        : 'confirm_time_slot',
    event_params : {
      ...eventParams,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const continueShopping = ( ) => {
  const event = {
    event        : 'continue_shopping',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToDeleteService = ( ) => {
  const event = {
    event        : 'go_to_delete_service',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goBack = ( page ) => {
  const event = {
    event        : 'go_back',
    event_params : {
      page,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToHome = ( ) => {
  const event = {
    event        : 'go_to_home',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToMyInfo = ( ) => {
  const event = {
    event        : 'go_to_my_info',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToMyPaymentMethods = ( ) => {
  const event = {
    event        : 'go_to_my_payment_methods',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToOtherPaymentMethods = ( ) => {
  const event = {
    event        : 'go_to_other_payment_methods',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToPay = ( ) => {
  const event = {
    event        : 'go_to_pay',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToSwitchVehicle = ( ) => {
  const event = {
    event        : 'go_to_switch_vehicle',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToViewServices = ( ) => {
  const event = {
    event        : 'go_to_view_services',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const goToOrderBy = ( ) => {
  const event = {
    event        : 'go_to_order_by',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const switchVehicle = ( ) => {
  const event = {
    event        : 'switch_vehicle',
    event_params : {
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const closeDialog = ( name ) => {
  const event = {
    event        : 'close_dialog',
    event_params : {
      dialogue  : name,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const onOpenLink = ( data ) => {
  if( window.dataLayer?.at( -1 ).event === 'on_open_link' ) return;
  const event = {
    event        : 'on_open_link',
    event_params : {
      ...data,
      user_uuid : getUserUuid()
    }
  };
  if( mixpanelIsActive() ) trackEvent( event.event, event.event_params );
  if( gtmIsActive() ) window.dataLayer.push( event );
};

const gtm = {
  vpv,
  addToCart,
  deleteService,
  addPaymentInfo,
  signUp,
  login,
  logout,
  search,
  goToSignup,
  goToLogin,
  goToRecoverAccount,
  checkTermsAndConditionsBox,
  goToRequestService,
  goToCurrentService,
  goToViewAddresses,
  goToCart,
  goToVehicle,
  deleteVehicle,
  goToPreviousService,
  pay,
  changeSelectedVehicle,
  goToAddVehicle,
  goToCategory,
  selectService,
  goToOrderBy,
  selectAlly,
  removeOption,
  addOption,
  addComments,
  checkDeliverAnotherAddress,
  goToChangeDeliveryAddress,
  goToAddCoupon,
  addCoupon,
  goToSummary,
  selectPaymentMethod,
  continueAddVehicle,
  addVehicle,
  viewVehicleServices,
  viewVehicleInfo,
  goToAddAddress,
  continueAddAddress,
  addAddress,
  addPersonalInformation,
  goToSetDateTime,
  changeDayDate,
  selectTimeSlot,
  confirmTimeSlot,
  continueShopping,
  goToDeleteService,
  goBack,
  goToHome,
  goToMyInfo,
  goToMyPaymentMethods,
  goToOtherPaymentMethods,
  goToPay,
  goToSwitchVehicle,
  goToViewServices,
  orderBy,
  switchVehicle,
  closeDialog,
  onOpenLink
};

export default gtm;
