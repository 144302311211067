import * as actionTypes from '../actions/types';


const INITIAL_STATE = {
  shops                  : undefined,
  areShopsLoading        : false,
  shopsError             : '',
  shopAgreement          : undefined,
  isShopAgreementLoading : false,
  shopAgreementError     : ''
};

export default ( state = INITIAL_STATE, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_SHOPS_LOADING:
      return {
        ...state,
        areShopsLoading : true,
        shopsError      : ''
      };
    case actionTypes.GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops           : action.payload,
        areShopsLoading : false,
        shopsError      : ''
      };
    case actionTypes.GET_SHOPS_ERROR:
      return {
        ...state,
        areShopsLoading : false,
        shopsError      : action.payload
      };
    case actionTypes.GET_SHOP_AGREEMENT_LOADING:
      return {
        ...state,
        isShopAgreementLoading : true,
        shopAgreementError     : ''
      };
    case actionTypes.GET_SHOP_AGREEMENT_SUCCESS:
      return {
        ...state,
        shopAgreement          : action.payload,
        isShopAgreementLoading : false,
        shopAgreementError     : ''
      };
    case actionTypes.GET_SHOP_AGREEMENT_ERROR:
      return {
        ...state,
        isShopAgreementLoading : false,
        shopAgreementError     : action.payload
      };
    case actionTypes.CLEAR_SHOP_AGREEMENT:
      return {
        ...state,
        isShopAgreementLoading : false,
        shopAgreement          : undefined,
        shopAgreementError     : ''
      };

    default:
      return state;
  }
};
